// #region functions
import cx from '@functions/classNames';
// #endregion

/**
 * inline loading text
 * @returns {component} jsx component
 */
const LoadingInline = () => {
    return (
        <div
            className={cx(
                'h-4',
                'mt-4',
                'mb-6',
                'flex',
                'flex-row',
                'text-lg',
                'text-black',
                'dark:text-white',
                'items-center'
            )}
        >
            <div className={cx('i-[gg-spinner]', 'mr-2', 'animate-spin')} />
            <span className={cx('font-bold')}>Loading...</span>
        </div>
    );
};

/**
 * block loading text
 * @returns {component} jsx component
 */
const LoadingBlock = () => {
    return (
        <div
            className={cx(
                'w-10',
                'min-h-full',
                'py-20',
                'flex',
                'flex-row',
                'items-center',
                'justify-center',
                'bg-gray-200',
                'dark:bg-gray-800'
            )}
        >
            <div className={cx('i-[gg-spinner]', 'mr-2', 'text-2xl', 'animate-spin')} />
            <span className={cx('text-2xl', 'font-bold')}>Loading...</span>
        </div>
    );
};

/**
 * page loading screen
 * @returns {component} jsx component
 */
const LoadingPage = () => {
    return (
        <div
            className={cx(
                'w-full',
                'h-full',
                'top-0',
                'left-0',
                'fixed',
                'block',
                'text-black',
                'dark:text-white',
                'bg-white',
                'dark:bg-gray-900',
                'z-50'
            )}
        >
            <span className={cx('w-0', 'h-0', 'my-0', 'mx-auto', 'top-1/2', 'relative', 'block')}>
                <div className={cx('i-[gg-spinner]', 'mr-2', 'text-6xl', 'animate-spin')} />
            </span>
        </div>
    );
};

export { LoadingInline, LoadingBlock, LoadingPage };
