// #region strings
import strings from '@strings/global.strings';
// #endregion

/**
 * get site language
 * @param {string} urlLanguage current language from url params
 * @returns {object} {availableLanguages, siteLanguage}
 */
const getLanguage = (urlLanguage) => {
    // define sources
    const availableLanguages = strings.getAvailableLanguages();
    const rawBrowserLanguage = strings.getInterfaceLanguage();
    const userLanguage = rawBrowserLanguage.substring(0, 2).toLowerCase();

    // compare sources
    const siteLanguage =
        urlLanguage && availableLanguages.includes(urlLanguage)
            ? urlLanguage
            : userLanguage && availableLanguages.includes(userLanguage)
            ? userLanguage
            : 'en';

    return { availableLanguages, siteLanguage };
};

/**
 * set site language
 * @param {string} value language to set
 * @returns {string} current language
 */
const setLanguage = (value) => {
    // update dom language
    const root = window.document.documentElement;
    root.setAttribute('lang', value);

    // update data language
    strings.setLanguage(value);

    return value;
};

export { getLanguage, setLanguage };
