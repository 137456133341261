// #region strings
import data from '@strings/static.strings';
// #endregion

const landing = {
    taglines: ['beyond the limits', 'Web Development', 'IT Consulting', 'IT Training'],
    chevron: 'Mehr erfahren'
};

const about = {
    title: 'Über mich',
    content: [
        'Mein Name ist Sven Sommerbauer.',
        `Ich bin ein ausgebildeter Web Entwickler, Netzwerkadministrator und IT-Security Manager aus Österreich. Ich habe ${
            new Date().getFullYear() - 2009
        } Jahre Berufserfahrung, in denen ich meine Fachkenntnisse durch umfangreiche praktische Arbeit und kontinuierliche Weiterbildung entwickelt habe.`,
        'Als professioneller Programmierer kann ich von der klassischen Firmenwebseite bis hin zu Online-Shops jede nur erdenkliche digitale Lösung für Ihr Unternehmen konzipieren, entwickeln und programmieren.',
        'Kontaktieren Sie mich für eine kostenlose Erstberatung, erklären Sie mir Ihre Ziele und ich entwickle anschließend eine moderne und ansprechende Lösung zu einem fairen Preis- Leistungsverhältnis.'
    ],
    tech: {
        title: 'Tech Stack',
        stack: data.stack
    }
};

const services = {
    title: 'Services',
    content:
        'Ich denke nicht in Kategorien, sondern in gezielten und kreativen Ideen, und berate Sie umfassend und professionell.',
    boxes: [
        {
            title: 'Entwicklung',
            text: 'Bei mir erhalten Sie Design und Programmierung aus einer Hand, und ich begleite Sie in allen Phasen Ihres Projekts.'
        },
        {
            title: 'Webseiten',
            text: 'Ich erstelle einen ansprechenden Webauftritt für Ihr Unternehmen, mit modernen Technologien wie NodeJS und React.'
        },
        {
            title: 'eCommerce',
            text: 'Mit Wordpress und WooCommerce kann ich Ihnen hochwertige Online Shops mit zugeschnittenen Designlösungen und leichter Bedienbarkeit bieten.'
        },
        {
            title: 'Technisches SEO',
            text: 'Eine schnelle und sichere Webseite zu haben ist heutzutage sehr wichtig. Ich kann Ihren Quellcode und Ihre Servereinstellungen auf Geschwindigkeit und Sicherheit optimieren.'
        },
        {
            title: 'Sicherheit',
            text: 'Jede Webseite sollte vor Angriffen geschützt sein. Wenn Sie Ihre Webseite professionell absichern lassen möchten, bin ich genau der Richtige für Sie.'
        },
        {
            title: 'Support',
            text: 'Sie haben schon eine Webseite, Sie haben aber manchmal die ein oder andere Frage? Ich helfe Ihnen gerne mit Ihrem Anliegen weiter.'
        }
    ]
};

const portfolio = {
    title: 'Portfolio',
    content: 'Hier ein kleiner Auszug meiner Referenzen:',
    boxes: [
        {
            title: 'Test 1',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            image: 'https://images.unsplash.com/photo-1457282367193-e3b79e38f207?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1654&q=80',
            tags: ['moon', 'nolimit'],
            link: 'https://google.com/'
        },
        {
            title: 'Test 2',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            image: 'https://images.unsplash.com/photo-1465188162913-8fb5709d6d57?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
            tags: ['moon', 'nolimit'],
            link: 'https://google.com/'
        },
        {
            title: 'Test 3',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            image: 'https://images.unsplash.com/photo-1467238307002-480ffdd260f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
            tags: ['moon', 'nolimit'],
            link: 'https://google.com/'
        },
        {
            title: 'Test 4',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            image: 'https://images.unsplash.com/photo-1457282367193-e3b79e38f207?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1654&q=80',
            tags: ['moon', 'nolimit'],
            link: 'https://google.com/'
        },
        {
            title: 'Test 5',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            image: 'https://images.unsplash.com/photo-1465188162913-8fb5709d6d57?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
            tags: ['moon', 'nolimit'],
            link: 'https://google.com/'
        },
        {
            title: 'Test 6',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            image: 'https://images.unsplash.com/photo-1467238307002-480ffdd260f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
            tags: ['moon', 'nolimit'],
            link: 'https://google.com/'
        }
    ]
};

const contact = {
    title: 'Kontakt Aufnehmen!',
    lead: 'Ich würde gerne von Ihnen hören! Wenn Sie Fragen zu meinen Diensten haben, kontaktieren Sie mich bitte und ich werde mich so schnell wie möglich bei Ihnen melden.',
    form: {
        title: 'Kontaktformular',
        error: {
            invalid: 'Ungültiges Zeichen',
            required: '{fieldName} ist erforderlich',
            minLength: 'Mehr Zeichen eingeben',
            maxLength: 'Maximale Zeichenanzahl erreicht',
            pattern: 'Ungültige Email-Adresse'
        },
        fields: {
            name: 'Name',
            email: 'E-Mail',
            message: 'Nachricht',
            privacy: 'Ich habe die {policy} gelesen und akzeptiere sie',
            approval: 'Zustimmung',
            honey: 'Honeypot'
        },
        msgCount: '{n} Zeichen übrig',
        send: 'Senden',
        status: {
            success: {
                title: 'Vielen Dank!',
                lead: 'Ihre Nachricht wurde abgeschickt. Ich werde mich in Kürze bei Ihnen melden.'
            },
            error: {
                title: 'Fehler beim Senden Ihrer Nachricht.',
                box: 'Fehlercode'
            }
        }
    }
};

const imprint = {
    title: 'Impressum',
    lead: 'Verantwortlich für den Inhalt und Betreiber der Webseite:',
    info: {
        ...data.imprint,
        region: 'Niederösterreich',
        countryFull: 'Österreich',
        vatLabel: 'Steuer-ID',
        showDetails: 'Kontaktdaten anzeigen'
    },
    legal: {
        lead: 'Informationen gemäß §5 des E-Commerce-Gesetzes, §14 des Unternehmensgesetzbuchs, §63 des Handelsgesetzbuches und Offenlegungspflichten nach §25 des Mediengesetzes.',
        content: [
            { title: 'Gegenstand des Unternehmens', text: 'Web Development, IT Beratung, IT Training' },
            { title: 'Gewerberechtliche Vorschriften', text: 'Gewerbeordnung (ris.bka.gv.at)', link: '{ris}' },
            { title: 'Handelsbehörde', text: 'Bezirkshauptmannschaft Mödling', link: '{bh}' },
            { title: 'Mitglied von', text: 'WKO Niederösterreich', link: '{wko}' }
        ]
    },
    ads: {
        title: 'keine Direktwerbung',
        text: 'Bitte schicken Sie mir {ads}!'
    },
    map: { title: 'Google Maps', styles: data.map.styles }
};

const main = {
    sections: {
        landing,
        about,
        services,
        portfolio,
        contact,
        imprint
    }
};

export default main;
