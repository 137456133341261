// #region strings
import strings from '@strings/global.strings';
// #endregion

// get site infos
const siteHostname = strings.url;
const header = strings.header;
const { info } = strings.pages.main.sections.imprint;

/**
 * dynamic ldJson object
 * @type {object} see documentation: https://json-ld.org/
 */
const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: header.title,
    legalName: header.title,
    description: header.description,
    url: `https://${siteHostname}/`,
    logo: `https://${siteHostname}/${header.logo}`,
    email: info.email.replace('@', '[at]').replace('.', '[dot]'),
    telephone: info.phone.replace(/\(0\)|\/| /gi, ''),
    address: {
        '@type': 'PostalAddress',
        streetAddress: info.street,
        addressLocality: info.city,
        addressRegion: info.region,
        postalCode: info.zip,
        addressCountry: info.countryFull
    },
    foundingDate: info.foundingDate,
    founders: [
        {
            '@type': 'Person',
            name: info.name
        }
    ],
    sameAs: info.sameAs
};

export default ldJson;
