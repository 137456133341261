// #region modules
import { useEffect, useCallback } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import shallow from 'zustand/shallow';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    snapshot: state.snapshot,
    consent: state.consent
});

/**
 * send pageview event to googletagmanager
 * @param {string} page current page
 * @returns {bool} done
 */
const usePageview = (page = 'main') => {
    // add state
    const { snapshot, consent } = useStore(stateSelector, shallow);

    // setup gtm hook dispatch event
    const sendDataToGTM = useGTMDispatch();

    // handle event
    const handlePageview = useCallback(() => {
        if (!snapshot && consent) {
            sendDataToGTM({ event: 'page_view' });
        }
    }, [consent, sendDataToGTM, snapshot]);

    // fire on initial page load and on each page change
    useEffect(() => {
        // the timeout is a nasty workaround for https://github.com/nfl/react-helmet/issues/189
        const handleTimeout = setTimeout(() => handlePageview(), 100);
        return () => clearTimeout(handleTimeout);
    }, [handlePageview, page]);

    return true;
};

export default usePageview;
