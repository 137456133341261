// #region modules
import { memo } from 'react';
// #endregion
// #region components
import MainBar from '@components/navbar/MainBar';
import HelpersBar from '@components/navbar/HelpersBar';
import LogoButton from '@components/navbar/LogoButton';
// #endregion
// #region functions
import cx from '@functions/classNames';
// #endregion
// #region types
import { navProps } from '@types/PropTypes';
// #endregion

/**
 * responsive sticky navbar
 * @param {bool} snapshot static site
 * @param {bool} isMain main page
 * @param {number} scrollPosition current scroll position
 * @returns {component} jsx component
 */
const Nav = memo(({ snapshot = false, isMain = true, scrollPosition = 0 }) => {
    const headerClass = [
        'sticky',
        'top-0',
        'z-50',
        'bg-white',
        'dark:bg-neutral-900',
        'drop-shadow-sm',
        'transition-all',
        'duration-500',
        !snapshot && isMain && scrollPosition < 100 ? 'dark:bg-opacity-0' : 'dark:bg-opacity-100'
    ];

    const navClass = [
        'container',
        'mx-auto',
        'px-6',
        'py-4',
        'flex-row',
        'flex',
        'items-center',
        snapshot ? (isMain ? ['justify-center', 'md:justify-between'].join(' ') : 'justify-between') : 'justify-between'
    ];

    return (
        <header className={cx(headerClass)}>
            <nav className={cx(navClass)}>
                <LogoButton />
                <MainBar />
                <HelpersBar />
            </nav>
        </header>
    );
});

Nav.propTypes = navProps;

export default Nav;
