// #region modules
import createStore from 'zustand';
import { persist, devtools } from 'zustand/middleware';
// #endregion

// initial settings
const settings = {
    readonly: {
        // check if production build
        production:
            process.env.NODE_ENV === 'production' &&
            !document.cookie.split('; ').find((cookie) => cookie.split('=')[0] === process.env.REACT_APP_DEV_COOKIE),

        // check if snapshot (static files)
        snapshot: navigator.userAgent === 'ReactSnap',

        // html root element
        root: 'root',

        // google maps config
        map: {
            key: process.env.REACT_APP_MAPS_API_KEY,
            center: {
                lat: 48.10082331347433,
                lng: 16.280981815549275
            },
            zoom: 16
        }
    },
    writeable: {
        // cookie consent for analytics
        consent: false,

        // useSize/useFocus hook
        window: {
            width: window.innerWidth,
            height: window.innerHeight,
            focus: typeof document !== 'undefined' && document.hasFocus()
        },

        // useScroll hook
        scroll: {
            position: 0,
            percent: 0,
            direction: 'none'
        },

        // page informations
        page: {
            language: 'de',
            current: 'main',
            isMain: true
        },

        // form validation
        form: {
            values: { name: '', email: '', message: '', privacy: false, _honey: '' },
            errors: {},
            touched: {},
            submit: {
                status: false,
                error: null
            }
        },

        // count textarea characters
        msgCount: 1000,

        // landing tag animation
        tagline: {
            currentId: 0,
            animation: { mass: 1, tension: 300, friction: 50 }
        },

        // hamburger menu toggle
        mobile: {
            toggle: false,
            breakpoint: 768
        },

        // intersection observer
        visible: {
            map: false, // google maps
            contact: false // contact details
        }
    },
    persist: {
        // useTheme hook
        theme:
            navigator.userAgent === 'ReactSnap'
                ? 'dark'
                : window.matchMedia('(prefers-color-scheme: dark)').matches
                ? 'dark'
                : 'light'
    }
};

/**
 * setup reducer
 * @param {object} state current state
 * @param {object} args { type, value }
 * @returns {object} new state
 */
const reducer = (get, { type, value }) => {
    // generate types
    const types = Object.keys({ ...settings.writeable, ...settings.persist });

    // generate payload
    const payload =
        // type and value validation
        typeof type !== 'undefined' && typeof value !== 'undefined' && types.includes(type)
            ? {
                  [type]:
                      typeof value === 'object'
                          ? { ...get()[type], ...value } // merge with existing object
                          : value // string|number|boolean
              }
            : get();

    return payload;
};

// internal config
const config = {
    // persist middleware
    persist: {
        name: 'root',
        partialize: (state) =>
            Object.fromEntries(Object.entries(state).filter(([key]) => Object.keys(settings.persist).includes(key))),
        getStorage: () => localStorage
    },

    // create state and dispatch
    state: (set, get) => ({
        ...Object.entries(settings).reduce((reduced, category) => Object.assign(reduced, category[1]), {}),
        dispatch: ({ type, value, log }) => set({ ...reducer(get, { type, value }) }, false, log)
    }),

    // add logging
    log: {
        enabled: !settings.readonly.production,
        options: { name: 'debug', anonymousActionType: 'dispatch', trace: true }
    }
};

/**
 * setup persist module
 * @param {object} state config.state
 * @param {object} config state
 * @returns {function} state
 */
const persistStore = persist(config.state, config.persist);

/**
 * setup store hook
 * @param {function} state persist
 * @returns {function} store
 */
const useStore = createStore(config.log.enabled ? devtools(persistStore, config.log.options) : persistStore);

export default useStore;
