// #region strings
import data from '@strings/static.strings';
// #endregion

const { url, themes, header, nav, footer } = data;

const global = {
    url,
    themes,
    header: {
        ...header,
        description: 'Ich bin ein ausgebildeter Web Entwickler, Netzwerkadministrator und IT-Security Manager aus Österreich.',
        keywords: [
            'moonbyte',
            'm00nbyte',
            'entwickler',
            'programmierer',
            'coder',
            'ecommerce',
            'e-commerce',
            'it beratung',
            'it sicherheit',
            'netzwerk sicherheit',
            'web sicherheit',
            'sicherheit',
            'html',
            'html5',
            'css',
            'css3',
            'javascript',
            'typescript',
            'nextjs',
            'tailwindcss',
            'mongodb',
            'redis'
        ]
    },
    nav: {
        menu: 'Menü',
        logo: nav.logo,
        home: nav.home,
        main: ['Über', 'Services', 'Kontakt', 'Impressum'].map((item, index) => ({
            //'Portfolio',
            title: item,
            element: nav.main[index]
        })),
        btt: 'back to top' // back to top button text
    },
    language: {
        switch: 'Switch to English'
    },
    footer: {
        links: ['Haftungsausschluss', 'Datenschutzerklärung'].map((item, index) => ({
            title: item,
            page: footer.links[index]
        })),
        icons: footer.icons,
        tags: footer.tags,
        source: { title: 'Diese Seite ist {link}', link: 'Open Source auf Github' }
    },
    enableJS: {
        text: '{component} benötigt JavaScript',
        button: 'Mehr erfahren'
    },
    consent: {
        manageCookies: 'Cookie Einstellungen verwalten',
        learnMore: 'Mehr erfahren...'
    }
};

export default global;
