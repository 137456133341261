// #region modules
import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// #endregion
// #region layout
import Layout from '@layout/Layout';
import { LoadingPage as Loading } from '@layout/Loading';
// #endregion
// #region functions
import ck from '@functions/customKey';
// #endregion
// #region hooks
import { getLanguage } from '@hooks/useLanguage';
// #endregion
// #region lazy pages
const Main = lazy(() => import(/* webpackChunkName: "page.main" */ '@pages/Main'));
const Privacy = lazy(() => import(/* webpackChunkName: "page.privacy" */ '@pages/Privacy'));
const Disclaimer = lazy(() => import(/* webpackChunkName: "page.disclaimer" */ '@pages/Disclaimer'));
const Ads = lazy(() => import(/* webpackChunkName: "page.ads" */ '@pages/Ads'));
const Error = lazy(() => import(/* webpackChunkName: "page.error" */ '@pages/Error'));
// #endregion

/**
 * custom multi-language routes
 * @returns {router} jsx component
 */
const CustomRoutes = () => {
    const { availableLanguages } = getLanguage();

    return (
        <Router>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/" element={<Layout />} />
                    {availableLanguages.map((item) => (
                        <Route key={ck({ name: 'main|layout|route', item })} path={item} element={<Layout />}>
                            <Route path="main" element={<Main />} />
                            <Route path="privacy" element={<Privacy />} />
                            <Route path="disclaimer" element={<Disclaimer />} />
                            <Route path="ads" element={<Ads />} />
                        </Route>
                    ))}
                    <Route path="*" element={<Layout />}>
                        <Route path="*" element={<Error />} />
                    </Route>
                </Routes>
            </Suspense>
        </Router>
    );
};

export default CustomRoutes;
