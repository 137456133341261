// #region modules
import { string, number, array, object, bool, func, shape, arrayOf, oneOf, oneOfType } from 'prop-types';
// #endregion

/**
 * component has children
 */
export const hasChildren = {
    children: oneOfType([array, object]).isRequired
};

/**
 * Hooks.jsx
 */
export const hooksProps = {
    ...hasChildren
};

/**
 * Providers.jsx
 */
export const providersProps = {
    ...hasChildren
};

/**
 * View.jsx
 */
export const viewProps = {
    ...hasChildren
};

/**
 * TagManager.jsx
 */
export const tagManagerProps = {
    ...hasChildren
};

/**
 * Header.jsx
 */
export const headerProps = {
    isProduction: bool.isRequired,
    currentPage: string.isRequired,
    currentLanguage: string.isRequired
};

/**
 * Navbar.jsx
 */
export const navProps = {
    snapshot: bool.isRequired,
    isMain: bool.isRequired,
    scrollPosition: number.isRequired
};

/**
 * Section.jsx
 */
export const sectionProps = {
    id: string.isRequired,
    classes: oneOfType([string, array]),
    ...hasChildren
};

/**
 * Title.jsx
 */
export const titleProps = {
    type: string.isRequired,
    text: string.isRequired,
    classes: oneOfType([string, array])
};

/**
 * EnableJS.jsx
 */
export const enableJsProps = {
    name: string.isRequired
};

/**
 * SimpleAlert.jsx
 */
export const simpleAlertProps = {
    type: string.isRequired,
    text: string.isRequired,
    button: object
};

/**
 * DevIcon.jsx
 */
export const devIconProps = {
    name: string.isRequired
};

/**
 * MenuButton.jsx
 */
export const menuButtonProps = {
    item: shape({
        title: string,
        element: string
    })
};

/**
 * SlideButton.jsx
 */
export const slideButtonProps = {
    title: string.isRequired,
    back: string.isRequired,
    slide: bool.isRequired
};

/**
 * BackToTop.jsx
 */
export const backToTopProps = {
    windowWidth: number.isRequired,
    scrollPosition: number.isRequired,
    mobileBreakpoint: number.isRequired,
    topElement: string.isRequired
};

/**
 * ManageConsent.jsx
 */
export const manageConsentProps = {
    currentTheme: string.isRequired,
    windowWidth: number.isRequired,
    scrollPosition: number.isRequired,
    mobileBreakpoint: number.isRequired
};

/**
 * LanguageSwitch.jsx
 */
export const languageSwitchProps = {
    page: string.isRequired,
    language: string.isRequired
};

/**
 * ContactForm.jsx
 */
export const contactFormProps = {
    options: shape({
        language: string.isRequired,
        values: object.isRequired,
        errors: object.isRequired,
        touched: object.isRequired,
        submit: object.isRequired
    }).isRequired,
    handles: shape({
        handleOut: func.isRequired,
        handleChange: func.isRequired,
        handleSubmit: func.isRequired
    }).isRequired
};

/**
 * FormError.jsx
 */
export const formErrorProps = {
    data: shape({
        title: string.isRequired,
        box: string.isRequired,
        code: oneOfType([string, number])
    }).isRequired
};

/**
 * FormSuccess.jsx
 */
export const formSuccessProps = {
    data: shape({
        title: string.isRequired,
        lead: string.isRequired
    }).isRequired
};

/**
 * Inputs Template
 */
const defaultInputProps = {
    basic: {
        type: string.isRequired,
        classes: oneOfType([string, object, arrayOf(string)]),
        error: string
    },
    control: {
        name: string.isRequired,
        required: bool.isRequired,
        touched: bool
    },
    content: {
        value: string.isRequired,
        placeholder: string.isRequired,
        minLength: string.isRequired,
        maxLength: string.isRequired
    }
};

const combinedInputProps = Object.entries(defaultInputProps).reduce(
    (reduced, category) => Object.assign(reduced, category[1]),
    {}
);

const inputHandles = shape({
    handleChange: func.isRequired,
    handleOut: func.isRequired
}).isRequired;

/**
 * TextInput.jsx
 */
export const inputTextProps = {
    options: shape({
        ...combinedInputProps,
        type: string.isRequired
    }),
    handles: inputHandles
};

/**
 * Textarea.jsx
 */
export const inputTextareaProps = {
    options: shape({
        ...combinedInputProps,
        type: string.isRequired,
        rows: string.isRequired,
        cols: string.isRequired
    }),
    handles: inputHandles
};

/**
 * Checkbox.jsx
 */
export const inputCheckboxProps = {
    options: shape({
        ...defaultInputProps.basic,
        ...defaultInputProps.control,
        type: string.isRequired,
        label: oneOfType([array, string])
    }),
    handles: inputHandles
};

/**
 * Button.jsx
 */
export const inputButtonProps = {
    options: shape({
        ...defaultInputProps.basic,
        type: string.isRequired,
        text: string.isRequired,
        submit: bool.isRequired
    })
};

/**
 * RenderInputs.jsx
 */
export const renderInputsProps = {
    input: shape({
        [string]: oneOf([inputTextProps, inputTextareaProps, inputCheckboxProps, inputButtonProps])
    }).isRequired,
    handles: inputHandles
};

/**
 * InputError.jsx
 */
export const inputErrorBadgeProps = {
    text: string.isRequired
};

export const inputErrorTextProps = {
    text: string.isRequired
};

/**
 * TextBlocks Template
 */
const textBlockTemplate = {
    title: string.isRequired,
    text: arrayOf(oneOfType([string, arrayOf(oneOfType([string, arrayOf(string)]))]))
};

/**
 * TextBlocks.jsx
 */
export const textBlockProps = {
    item: shape(textBlockTemplate),
    sub: bool.isRequired
};

export const textBlocksProps = {
    items: arrayOf(shape(textBlockTemplate)),
    sub: bool.isRequired
};

/**
 * Paragraphs.jsx
 */
export const paragraphProps = {
    item: shape({
        ...textBlockTemplate,
        sub: arrayOf(shape(textBlockTemplate))
    })
};

export const paragraphsProps = {
    items: arrayOf(shape(paragraphProps))
};

/**
 * StackList.jsx
 */
export const stackListProps = {
    stackList: arrayOf(string.isRequired).isRequired
};

/**
 * Cards Template
 */
const basicCardTemplate = {
    title: string.isRequired,
    text: string.isRequired
};

/**
 * ServiceCard.jsx
 */
export const serviceCardProps = {
    item: shape({
        ...basicCardTemplate
    }).isRequired
};

/**
 * PortfolioCard.jsx
 */
export const portfolioCardProps = {
    item: shape({
        ...basicCardTemplate,
        image: string.isRequired,
        tags: arrayOf(string.isRequired).isRequired,
        link: string.isRequired
    }).isRequired
};

/**
 * Links.jsx
 */
export const internalLinkProps = {
    item: shape({
        title: string.isRequired,
        page: string.isRequired
    })
};

/**
 * SocialIcon.jsx
 */
export const socialIconProps = {
    item: shape({
        icon: string.isRequired,
        url: string.isRequired
    }).isRequired
};

/**
 * CustomMap.jsx
 */
export const customMapProps = {
    apiKey: string.isRequired,
    id: string.isRequired,
    options: shape({
        center: shape({
            lat: number.isRequired,
            lng: number.isRequired
        }).isRequired,
        zoom: number.isRequired
    }),
    onMapLoad: func.isRequired
};

/**
 * ScreenSize.jsx
 */
export const screenSizeProps = {
    windowWidth: number.isRequired,
    windowHeight: number.isRequired
};
