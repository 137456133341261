const disclaimer = {
    title: 'Haftungsausschluss',
    paragraphs: [
        {
            title: 'EU-Streitbeilegung',
            text: [
                'Verbraucher können Beschwerden bei der Online-Streitbeilegungsplattform der EU einreichen: {eu_odr}. Sie können Beschwerden auch an die im Impressum angegebene E-Mail-Adresse richten.'
            ]
        },
        {
            title: 'Haftung für den Inhalt dieser Website',
            text: [
                'Wir entwickeln den Inhalt dieser Website kontinuierlich weiter und bemühen uns, korrekte und aktuelle Informationen bereitzustellen. Leider können wir keine Haftung für die Richtigkeit aller Inhalte dieser Website übernehmen, insbesondere für die von Dritten bereitgestellten. Wenn Sie problematische oder illegale Inhalte bemerken, kontaktieren Sie uns bitte sofort.'
            ]
        },
        {
            title: 'Haftung für Links auf dieser Website',
            text: [
                'Unsere Website enthält Links zu anderen Websites, für deren Inhalt wir nicht verantwortlich sind. Eine Haftung für verlinkte Websites besteht für uns gemäß §17 EKG nicht, da wir keine Kenntnis von illegalen Aktivitäten hatten und solche illegalen Aktivitäten bisher nicht bemerkt haben und wir Links sofort entfernen würden, wenn wir Kenntnis von illegalen Aktivitäten erhalten würden. Wenn Sie illegale Links auf unserer Website bemerken, kontaktieren Sie uns bitte.'
            ]
        },
        {
            title: 'Eingebettete Inhalte von anderen Websites',
            text: [
                'Beiträge auf dieser Website können eingebetteten Inhalt enthalten (z. B. Videos, Bilder, Beiträge usw.). Eingebettete Inhalte von anderen Websites verhalten sich genauso, als hätte der Besucher die andere Website besucht.',
                'Diese Websites sammeln möglicherweise Daten über Sie, verwenden Cookies, binden zusätzliche Tracking-Dienste von Drittanbietern ein und zeichnen Ihre Interaktion mit diesem eingebetteten Inhalt auf, einschließlich Ihrer Interaktion mit dem eingebetteten Inhalt.'
            ]
        },
        {
            title: 'Hinweis im Sinne des Gleichbehandlungsgesetzes',
            text: [
                'Aus Gründen der besseren Lesbarkeit wird die geschlechtsspezifische Differenzierung auf dieser Website nicht kontinuierlich berücksichtigt. Entsprechende Begriffe gelten für alle Geschlechter im Sinne der Gleichbehandlung.'
            ]
        },
        {
            title: 'Urheberrechtshinweis',
            text: [
                'Alle Inhalte dieser Website (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls erforderlich, werden wir die unbefugte Verwendung von Teilen des Inhalts unserer Website rechtlich verfolgen. Wenn Sie auf dieser Website Inhalte finden, die gegen das Urheberrecht verstoßen, kontaktieren Sie uns bitte.'
            ]
        },
        {
            title: 'Bildnachweis',
            text: [
                'Die Bilder, Fotos und Grafiken auf dieser Website sind urheberrechtlich geschützt. Die Bildrechte liegen bei folgenden Fotografen / Grafikern: Sven Sommerbauer'
            ]
        }
    ]
};

export default disclaimer;
