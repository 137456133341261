// #region modules
import { useEffect, useCallback } from 'react';
import '@npm/vanilla-cookieconsent/dist/cookieconsent.css';
import '@npm/vanilla-cookieconsent/dist/cookieconsent.js';
import shallow from 'zustand/shallow';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion
// #region strings
import strings from '@strings/consent.strings';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    snapshot: state.snapshot,
    consent: state.consent,
    dispatch: state.dispatch
});

/**
 * setup cookie consent
 * @returns {bool} consent
 */
const useConsent = () => {
    // add state
    const { snapshot, consent, dispatch } = useStore(stateSelector, shallow);

    // handle change
    const handleUpdate = useCallback(
        (cookie) => {
            // update state
            const { level } = cookie;
            dispatch({ type: 'consent', value: level.includes('analytics'), log: 'consent/update' });
        },
        [dispatch]
    );

    // setup module
    const setupConsent = useCallback(() => {
        if (!snapshot) {
            // initialize module as window variable
            window.cc = window.initCookieConsent();

            // run module
            window.cc.run({
                revision: 0,
                delay: 4000,
                auto_language: 'document',
                gui_options: {
                    consent_modal: {
                        layout: 'cloud',
                        position: 'bottom center',
                        transition: 'slide',
                        swap_buttons: false
                    },
                    settings_modal: {
                        layout: 'box',
                        transition: 'slide'
                    }
                },
                languages: {
                    en: strings.en,
                    de: strings.de
                },
                onAccept: handleUpdate,
                onChange: handleUpdate
            });
        }
    }, [handleUpdate, snapshot]);

    // fire once
    useEffect(() => {
        setupConsent();
    }, [setupConsent]);

    return consent;
};

export default useConsent;
