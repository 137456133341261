// #region modules
import { memo } from 'react';
import shallow from 'zustand/shallow';
// #endregion
// #region components
import MenuButton from '@components/navbar/MenuButton';
import BackButton from '@components/navbar/BackButton';
import ThemeToggle from '@components/navbar/ThemeToggle';
// #endregion
// #region functions
import cx from '@functions/classNames';
import ck from '@functions/customKey';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion
// #region strings
import strings from '@strings/global.strings';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    snapshot: state.snapshot,
    windowWidth: state.window.width,
    mobileBreakpoint: state.mobile.breakpoint,
    mobileToggle: state.mobile.toggle,
    isMain: state.page.isMain
});

/**
 * navbar menu buttons wrap
 * @returns {component} jsx component
 */
const MenuButtons = memo(() => {
    const { main: text } = strings.nav;
    return text.map((item) => <MenuButton key={ck({ name: 'navbar|menu|button', item })} item={item} />);
});

/**
 *
 * navbar mainbar for desktop devices
 * @returns {component} jsx component
 */
const MainBar = () => {
    const { snapshot, windowWidth, mobileBreakpoint, mobileToggle, isMain } = useStore(stateSelector, shallow);

    const mobileClass = [
        'flex',
        'w-full',
        'h-screen',
        'left-0',
        'top-0',
        'justify-center',
        'bg-white',
        'space-y-3',
        'dark:bg-neutral-900',
        'text-3xl'
    ];

    return (
        <div
            className={cx(
                'absolute',
                'md:relative',
                'md:flex',
                'items-center',
                !mobileToggle && 'hidden',
                windowWidth < mobileBreakpoint && mobileToggle && mobileClass
            )}
        >
            <div
                className={cx(
                    'flex',
                    'flex-col',
                    'md:flex-row',
                    'md:mx-6',
                    windowWidth < mobileBreakpoint && mobileToggle && isMain && 'space-y-8'
                )}
            >
                {isMain ? <MenuButtons /> : <BackButton />}
            </div>

            {!snapshot && (
                <div className={cx('hidden', 'md:block')}>
                    <ThemeToggle />
                </div>
            )}
        </div>
    );
};

export default MainBar;
