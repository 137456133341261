// #region strings
import global from '@strings/de/page.global';
import main from '@strings/de/page.main';
import disclaimer from '@strings/de/page.disclaimer';
import privacy from '@strings/de/page.privacy';
import ads from '@strings/de/page.ads';
import error from '@strings/de/page.error';
// #endregion

const de = {
    ...global,
    pages: {
        main,
        disclaimer,
        privacy,
        ads,
        error
    }
};

export default de;
