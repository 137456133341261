// #region modules
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import shallow from 'zustand/shallow';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion
// #region types
import { tagManagerProps } from '@types/PropTypes';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    snapshot: state.snapshot
});

/**
 * setup google tag manager
 * * only with cookie consent
 * @param {component} children children component
 * @returns {component} jsx component
 */
const TagManager = ({ children = <></> }) => {
    const { snapshot } = useStore(stateSelector, shallow);

    // setup nonce
    const nonce = 'eYCiaksm3TzZWPOC1ZS9KA==';

    // gtm config
    const gtmParams = {
        id: process.env.REACT_APP_GTM_ID,
        injectScript: !snapshot,
        nonce
    };

    return <GTMProvider state={gtmParams}>{children}</GTMProvider>;
};

TagManager.propTypes = tagManagerProps;

export default TagManager;
