// #region functions
import cx from '@functions/classNames';
// #endregion
// #region types
import { sectionProps } from '@types/PropTypes';
// #endregion

/**
 * page section
 * @param {string} id unique id
 * @param {string|array} classes optional css class names
 * @param {component} children children component
 * @returns {component} jsx component
 */
const Section = ({ id = '', classes = '', children = <></> }) => {
    const defaultStyle = [
        'w-full',
        'min-h-screen',
        'pt-10',
        'pb-10',
        'flex',
        'flex-col',
        'justify-center',
        'items-center',
        // 'text-center',
        'bg-dynamic',
        'transition-all'
    ];

    return (
        <section id={id} className={cx(classes.length > 0 ? classes : defaultStyle)}>
            {children}
        </section>
    );
};

Section.propTypes = sectionProps;

export default Section;
