const privacy = {
    title: 'Datenschutzerklärung',
    paragraphs: [
        {
            title: 'Erklärung zur Informationspflicht',
            text: [
                'Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf der Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie auf unserer Website über die wichtigsten Aspekte der Datenverarbeitung.'
            ]
        },
        {
            title: 'Cookies',
            sub: [
                {
                    title: 'Verwendung von Cookies',
                    text: [
                        'Diese Website verwendet verschiedene HTTP-Cookies, um personenbezogene Daten zu speichern. Cookies sind digitale Datenpakete, mit denen Daten zwischen dem Webbrowser und dem Server ausgetauscht werden. Diese Daten werden nur in Kombination mit Webanwendungen wie Plugins, Analysetools und Online-Shops verwendet.',
                        'Es wird zwischen Erstanbieter-Cookies (direkt) oder Drittanbieter-Cookies (indirekt) unterschieden. Cookies von Drittanbietern sind beispielsweise Google Analytics- oder Facebook Pixel-Daten.'
                    ]
                },
                {
                    title: 'Beispiel für Cookie-Daten:',
                    text: [
                        'Cookies werden je nach Funktion in 3 Unterkategorien unterteilt.',
                        [
                            'unbedingt notwendige Cookies (für Grundfunktionen)',
                            'funktionale Cookies (Gewährleistung der Website-Leistung)',
                            'gezielte Cookies (Verbesserung der Benutzererfahrung)'
                        ],
                        'Durch die Verwendung von Cookies wird Ihr Browser bei wiederholten Besuchen sofort erkannt. Dies bietet Ihnen als Besucher eine benutzerfreundlichere Umgebung. Dadurch werden Webanwendungen, Bilder und Informationen schneller geladen. Einige Cookies bleiben auf Ihrem Gerät, bis Sie sie selbst in Ihren Browsereinstellungen löschen.'
                    ]
                },
                {
                    title: 'Cookie-Einstellungen anzeigen und löschen',
                    text: [
                        'Sie können schnell und einfach herausfinden, welche Cookies in Ihrem Browser gespeichert sind. Sie können auch die Einstellungen von Cookies ändern oder löschen. Erfahren Sie hier mehr über Ihren Browser: {cookiesandyou}',
                        'Wenn Sie nicht möchten, dass Cookies auf Ihrem Endgerät gespeichert werden, können Sie Ihren Browser so konfigurieren, dass er Sie jedes Mal informiert, wenn Cookies gesetzt werden sollen, und dass sie nur mit Genehmigung installiert werden. Cookies, die sich bereits auf Ihrem Gerät befinden, können jederzeit gelöscht, geändert oder deaktiviert werden. Sie können viele Informationen zu diesem Thema finden, wenn Sie beispielsweise die Begriffe "Cookies löschen" googeln.',
                        'Wenn Sie alle Cookies deaktivieren oder löschen, kann das reibungslose Funktionieren der Website, der Seiten, des Webshops und der Webanwendungen nicht mehr garantiert werden.'
                    ]
                }
            ]
        },
        {
            title: 'Kontakt mit uns',
            text: [
                'Wenn Sie uns über das Formular auf der Website oder per E-Mail kontaktieren, werden Ihre Daten von uns zum Zwecke der Bearbeitung der Anfrage und bei weiteren Fragen gespeichert. Diese Daten werden weder von Dritten eingesehen noch ohne Ihre Zustimmung weitergegeben.'
            ]
        },
        {
            title: 'form.taxi',
            text: [
                'Diese Webseite nutzt form.taxi, einen Webdienst der Webseite {ft_link} (nachfolgend "form.taxi"). Um Ihnen die Funktionalität des Formulars zur Verfügung zu stellen, senden wir die von Ihnen angegebene Daten an form.taxi. Diese Daten werden dort verarbeitet, gespeichert und an uns per E-Mail weitergegeben. Außerdem werden von form.taxi unter anderem weitere Daten wie Ihre IP-Adresse, Ihr Typ des Browsers, die Domain der Webseite, das Datum und die Zeit des Zugriffs erhoben, um die gewünschte Funktionalität des Formulars bereitzustellen. Rechtsgrundlage für die Nutzung von form.taxi ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO (berechtigtes Interesse). Die Datenverarbeitung und Speicherung erfolgt innerhalb der Europäischen Union. Weitere Informationen entnehmen Sie der Datenschutzerklärung von form.taxi: {ft_privacy}.'
            ]
        },
        {
            title: 'Google Maps',
            text: [
                'Diese Webseite benutzt das Google Maps Service der amerikanischen Firma Google Inc. mit Firmensitz in 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.',
                'Bei der Benutzung von Google Maps werden Standortdaten und Daten an Google weitergeleitet. Die von Google aufgezeichneten Daten und wie diese Daten verwertet werden, können Sie hier einsehen: {gm_policy}'
            ]
        },
        {
            title: 'Google Analytics',
            text: [
                'Diese Webseite benutzt das Google Analytics Service der amerikanischen Firma Google Inc. mit Firmensitz in 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.',
                'Google Analytics benutzt dabei zielgerichtete Cookies.'
            ],
            sub: [
                {
                    title: 'Beispiel von Google Analytics Cookiedaten:',
                    text: [
                        [
                            ['Name', '_ga'],
                            ['Laufzeit', '1 Jahr'],
                            ['Anwendungsgebiet', 'Webseitenbesucher und Tracking'],
                            ['Beispiel Wert', 'GA1.2.32343453535.1234535880111']
                        ],
                        [
                            ['Name', '_gid'],
                            ['Laufzeit', '1 Jahr'],
                            ['Anwendungsgebiet', 'Webseitenbesucher und Tracking'],
                            ['Beispiel Wert', 'GA1.2.32343453535.1234535880111']
                        ],
                        [
                            ['Name', 'gat_gtag_UA'],
                            ['Laufzeit', '3 Minuten'],
                            ['Anwendungsgebiet', 'zb. Integration von Google Analytics mit Google Tag Manager'],
                            ['Beispiel Wert', '3']
                        ],
                        'Weitere Informationen zu Google Analytics finden Sie hier:',
                        '{ga_marketing} oder {ga_support}'
                    ]
                },
                {
                    title: 'Pseudonymisierung von Daten',
                    text: [
                        'Wir sind ständig damit beschäftigt, unser Angebot und Präsens im Rahmen der DSGVO zu verbessern.',
                        'Die Privatsphäre unserer Kunden und Nutzer ist uns sehr wichtig, deswegen pseudonymisieren und verschlüsseln wir diese. Die Verarbeitung der Daten basiert auf der Basis der gesetzlichen Bestimmungen des §96 Abs 3 TKG sowie dem Art. 6 EU-DSGVO Abs 1 lit a (Einwilligung) und/oder f (berechtigtes Interesse) der DSGVO.'
                    ]
                },
                {
                    title: 'Google Analytics Datenabfrage deaktivieren',
                    text: [
                        'Um die Datenabfrage durch Google Analytics zu beenden, müssen Sie sich das folgende Browser Add-on herunterladen und installieren: {ga_optout}',
                        'Durch das Installieren des Plugins werden Google Analytics JavaScript Dateien nicht ausgeführt (analytics.js, dc.js, ga,js). Es können auch sämtliche von Google Analytics erfassten Webseiten und nutzerbezogenen Daten, so wie deren weitere Verarbeitung deaktiviert werden.'
                    ]
                },
                {
                    title: 'Zusatz zu Google Analytics und der Datenverarbeitung',
                    text: [
                        'Wir haben einen direkten Kundenvertrag mit Google zur Verwendung von Google Analytics. Im Zusammenhang mit dem geschlossenen Kundenvertrag haben wir auch den "Zusatz zur Datenverarbeitung" für Google Analytics akzeptiert.',
                        'Mehr zum Google Analytics Zusatz zur Datenverarbeitung finden Sie hier: {ga_addon}'
                    ]
                }
            ]
        },
        {
            title: 'Ihre Rechte',
            text: [
                'Grundsätzlich haben Sie das Recht auf Information, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch. Wenn Sie der Meinung sind, dass die Verarbeitung Ihrer Daten gegen das Datenschutzgesetz verstößt oder Ihre Datenschutzansprüche anderweitig verletzt wurden, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die Datenschutzbehörde.'
            ]
        }
    ]
};

export default privacy;
