// #region modules
import { useEffect } from 'react';
import shallow from 'zustand/shallow';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion
// #region strings
import strings from '@strings/global.strings';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    currentTheme: state.theme
});

/**
 * toggle dark/light theme
 * update <html class="dark|light">
 * update <body class="c_darkmode|c_lightmode">
 * @returns {string} current theme
 */
const useTheme = () => {
    // add state
    const { currentTheme: newTheme } = useStore(stateSelector, shallow);

    // handle event
    useEffect(() => {
        // get old theme
        const allThemes = strings.themes;
        const themeIndex = allThemes.findIndex((theme) => theme === newTheme);
        const oldTheme = allThemes[allThemes.length - 1 - themeIndex];

        // change style for whole site
        const root = window.document.documentElement;
        root.classList.remove(oldTheme);
        root.classList.add(newTheme);

        // change style for cookie consent
        const body = window.document.body;
        body.classList.remove(`c_${oldTheme}mode`);
        body.classList.add(`c_${newTheme}mode`);
    }, [newTheme]);

    return newTheme;
};

export default useTheme;
