// #region modules
import { useEffect, useCallback } from 'react';
import shallow from 'zustand/shallow';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    windowFocus: state.window.focus,
    dispatch: state.dispatch
});

/**
 * detect if tab is focused or not
 * @returns {bool} focus
 */
const useFocus = () => {
    // add state
    const { windowFocus, dispatch } = useStore(stateSelector, shallow);

    // handle event
    const handleChange = useCallback(
        (value) => {
            // update state
            dispatch({ type: 'window', value: { focus: value }, log: 'focus/update' });
        },
        [dispatch]
    );

    // hook into window focus|blur events
    useEffect(() => {
        // trigger update
        const onFocus = () => handleChange(true);
        const onBlur = () => handleChange(false);

        // setup listeners
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);

        // remove listeners
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, [handleChange]);

    return windowFocus;
};

export default useFocus;
