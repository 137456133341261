// #region modules
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
// #endregion
// #region functions
import cx from '@functions/classNames';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion
// #region strings
import strings from '@strings/global.strings';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    windowWidth: state.scroll.width,
    mobileBreakpoint: state.mobile.breakpoint,
    currentLanguage: state.page.language
});

/**
 * navbar back button
 * @returns {component} jsx component
 */
const BackButton = () => {
    const { windowWidth, mobileBreakpoint, currentLanguage } = useStore(stateSelector, shallow);
    const navigate = useNavigate();

    const text = strings.nav.home;

    const backClass = [
        'ga-bb',
        'md:mx-4',
        'md:my-0',
        'text-black',
        'dark:text-white',
        'hover:text-gray-600',
        'dark:hover:text-gray-300',
        'tracking-wider',
        'lowercase',
        'font-lead',
        'transition-all',
        'cursor-pointer',
        windowWidth >= mobileBreakpoint && ['custom-underline', 'before:right-0']
    ];

    return (
        <button
            className={cx(backClass)}
            onClick={() => {
                navigate(`/${currentLanguage}/main`);
            }}
        >
            {text}
        </button>
    );
};

export default BackButton;
