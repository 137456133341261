// #region modules
import shallow from 'zustand/shallow';
// #endregion
// #region functions
import cx from '@functions/classNames';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion
// #region strings
import strings from '@strings/global.strings';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    currentTheme: state.theme,
    dispatch: state.dispatch
});

/**
 * navbar theme toggle button
 * @returns {component} jsx component
 */
const ThemeToggle = () => {
    const { currentTheme, dispatch } = useStore(stateSelector, shallow);

    const toggleClass = [
        'ga-tt',
        'p-1',
        'text-black',
        'dark:text-white',
        // 'hover:scale-125',
        // 'transform',
        // 'transition',
        // 'duration-100',
        // 'ease-in-out',
        // 'rounded-full',
        'cursor-pointer'
    ];

    return (
        <div
            className={cx(toggleClass)}
            onClick={() => {
                dispatch({
                    type: 'theme',
                    value: strings.themes.filter((item) => item !== currentTheme).join(''),
                    log: 'theme/toggle'
                });
            }}
        >
            <div className={cx('text-xl', 'pointer-events-none')}>{currentTheme === 'dark' ? '☀️' : '🌒'}</div>

            {/* <div
                className={cx('i-[mdi-weather-sunny]', 'text-2xl', {
                    hidden: currentTheme === 'light'
                })}
            />
            <div
                className={cx('i-[mdi-weather-night]', 'text-2xl', {
                    hidden: currentTheme === 'dark'
                })}
            /> */}
        </div>
    );
};

export default ThemeToggle;
