const ads = {
    title: 'Keine Werbung',
    lead: [
        'Nach §107 TKG 2003 ist E-Mail Werbung grundsätzlich nur mit vorheriger Einwilligung des Empfängers oder im Rahmen einer bestehenden Geschäftsbeziehungen zulässig.',
        'Bitte senden Sie uns daher keine Direktwerbung per Telefon oder E-Mail!'
    ],
    ecg: {
        title: 'Elektronische Robinsonliste - (ECG Liste)',
        text: 'Die Domain moonbyte.at ist in der EKG Liste aufgeführt. Dies schließt auch alle E-Mail-Adressen mit ein. Durch die Aufnahme in die EKG Liste wird erneut ausdrücklich darauf hingewiesen, dass keine Direktwerbung an diese Domain gesendet werden darf.',
        link: 'Erfahren Sie mehr auf: {wko}'
    }
};

export default ads;
