// #region strings
import data from '@strings/static.strings';
// #endregion

const landing = {
    taglines: ['beyond the limits', 'Web Development', 'IT Consulting', 'IT Training'],
    chevron: 'Learn more'
};

const about = {
    title: 'About Me',
    content: [
        'My name is Sven Sommerbauer.',
        `I am a trained web developer, network administrator and IT security manager from austria. I bring ${
            new Date().getFullYear() - 2009
        } years of hands-on experience in the field, having developed my professional skills through extensive real-world practice and continuous learning.`,
        'As a professional coder, I can design, develop and program every imaginable digital solution for your company, from the classic company website to online shops.',
        'Contact me for a free initial consultation, explain your goals to me and I will then develop a modern and attractive solution at a fair price-performance ratio.'
    ],
    tech: {
        title: 'Tech stack',
        stack: data.stack
    }
};

const services = {
    title: 'Services',
    content:
        "I don't think in terms of categories, but in specific and creative ideas, and advise you comprehensively and professionally.",
    boxes: [
        {
            title: 'Development',
            text: 'With me you get design and programming from a single source, and I accompany you in all phases of your project.'
        },
        {
            title: 'Websites',
            text: 'I create an attractive website for your company using modern technologies such as NodeJS and React.'
        },
        {
            title: 'eCommerce',
            text: 'With Wordpress and WooCommerce I can offer you high-quality online shops with tailored design solutions and ease of use.'
        },
        {
            title: 'Technical SEO',
            text: 'Having a fast and secure website is very important these days. I can optimize your source code and server settings for speed and security.'
        },
        {
            title: 'Security',
            text: 'Every website should be protected from attacks. If you want to have your website professionally secured, I am exactly the right person for you.'
        },
        {
            title: 'Support',
            text: 'Do you already have a website, but you sometimes have a question or two? I will be happy to help you with your request.'
        }
    ]
};

const portfolio = {
    title: 'Portfolio',
    content: 'Here is a small excerpt from my references:',
    boxes: [
        {
            title: 'Test 1',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            image: 'https://images.unsplash.com/photo-1457282367193-e3b79e38f207?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1654&q=80',
            tags: ['moon', 'nolimit'],
            link: 'https://google.com/'
        },
        {
            title: 'Test 2',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            image: 'https://images.unsplash.com/photo-1465188162913-8fb5709d6d57?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
            tags: ['moon', 'nolimit'],
            link: 'https://google.com/'
        },
        {
            title: 'Test 3',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            image: 'https://images.unsplash.com/photo-1467238307002-480ffdd260f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
            tags: ['moon', 'nolimit'],
            link: 'https://google.com/'
        },
        {
            title: 'Test 4',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            image: 'https://images.unsplash.com/photo-1457282367193-e3b79e38f207?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1654&q=80',
            tags: ['moon', 'nolimit'],
            link: 'https://google.com/'
        },
        {
            title: 'Test 5',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            image: 'https://images.unsplash.com/photo-1465188162913-8fb5709d6d57?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
            tags: ['moon', 'nolimit'],
            link: 'https://google.com/'
        },
        {
            title: 'Test 6',
            text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            image: 'https://images.unsplash.com/photo-1467238307002-480ffdd260f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
            tags: ['moon', 'nolimit'],
            link: 'https://google.com/'
        }
    ]
};

const contact = {
    title: "Let's Get In Touch!",
    lead: 'I would love to hear from you! If you have any questions about my services, please contact me and I will get back to you as soon as possible.',
    form: {
        title: 'Contact form',
        error: {
            invalid: 'Invalid character',
            required: '{fieldName} is required',
            minLength: 'Enter more characters',
            maxLength: 'Maximum number of characters reached',
            pattern: 'Invalid email address'
        },
        fields: {
            name: 'Name',
            email: 'E-Mail',
            message: 'Message',
            privacy: 'I have read and accept the {policy}',
            approval: 'Approval',
            honey: 'Honeypot'
        },
        msgCount: '{n} characters left',
        send: 'Send',
        status: {
            success: {
                title: 'Thank you!',
                lead: 'Your message has been sent. I will get in touch with you shortly.'
            },
            error: {
                title: 'Error sending your message.',
                box: 'Error code'
            }
        }
    }
};

const imprint = {
    title: 'Imprint',
    lead: 'Responsible for the content and operator of the website:',
    info: {
        ...data.imprint,
        region: 'Lower Austria',
        countryFull: 'Austria',
        vatLabel: 'Tax-ID',
        showDetails: 'Show contact info'
    },
    legal: {
        lead: 'Information in accordance with §5 of the E-Commerce Act, §14 of the Unternehmensgesetzbuch, §63 of the Commercial Code and disclosure requirements under §25 of the Media Act.',
        content: [
            { title: 'Object of the company', text: 'Web Development, IT Consulting, IT Training' },
            { title: 'Laws re. professions', text: 'trade regulations (ris.bka.gv.at)', link: '{ris}' },
            { title: 'Trade authority', text: 'District authority Mödling', link: '{bh}' },
            { title: 'Member of', text: 'WKO Niederösterreich', link: '{wko}' }
        ]
    },
    ads: {
        title: 'no direct advertising',
        text: 'Please send me {ads}!'
    },
    map: { title: 'Google Maps', styles: data.map.styles }
};

const main = {
    sections: {
        landing,
        about,
        services,
        portfolio,
        contact,
        imprint
    }
};

export default main;
