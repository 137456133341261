// #region functions
import cx from '@functions/classNames';
// #endregion

/**
 * space between elements on mobile devices
 * @returns {component} jsx component
 */
const Space = () => {
    return <div className={cx('md:hidden', 'mt-4', 'mx-auto', 'w-11', 'h-px', 'rounded-full')}></div>;
};

export default Space;
