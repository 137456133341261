// #region modules
import LazyLoad from 'react-lazyload';
// #endregion
// #region functions
import cx from '@functions/classNames';
// #endregion
// #region strings
import strings from '@strings/global.strings';
// #endregion

/**
 * site tags
 * @returns {component} jsx component
 */
const SiteTags = () => {
    const text = strings.footer;

    return (
        <div
            className={cx(
                'mt-3',
                'md:mt-0',
                'md:pl-5',
                'flex-1',
                'flex',
                'flex-col',
                'items-center',
                'md:items-start',
                'justify-center',
                'font-display',
                'lowercase'
            )}
        >
            <span className={cx('flex items-center font-bold')}>
                {strings.formatString(text.tags.made, {
                    love: (
                        <LazyLoad height={16} offset={400} once>
                            <div
                                className={cx(
                                    'i-[bxs-heart]',
                                    'ml-1',
                                    'mr-1',
                                    'animate-heart',
                                    'text-rose-600',
                                    'inline-block'
                                )}
                            />
                        </LazyLoad>
                    )
                })}
            </span>
            <span className={cx('flex', 'items-center')}>
                {strings.formatString(text.tags.copy, {
                    icon: (
                        <LazyLoad height={16} offset={400} once>
                            <div className={cx('i-[la-copyright]', 'mr-1', 'text-black', 'dark:text-white')} />
                        </LazyLoad>
                    ),
                    copy: new Date().getFullYear()
                })}
            </span>
        </div>
    );
};

export default SiteTags;
