// #region modules
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
// #endregion
// #region strings
import strings from '@strings/global.strings';
import ldJson from '@strings/oo/ldJson.strings';
// #endregion
// #region types
import { headerProps } from '@types/PropTypes';
// #endregion

/**
 * setup useful meta tags mostly for seo
 * @param {bool} isProduction is production
 * @param {number} currentPage current page
 * @param {number} currentLanguage current language
 * @returns {component} jsx component
 */
const Header = memo(({ isProduction = false, currentPage = 'main', currentLanguage = 'de' }) => {
    const availableLanguages = strings.getAvailableLanguages();
    const oppositeLanguage = currentLanguage === 'en' ? 'de' : 'en';
    const localeSuffixMap = { en: 'US', de: 'DE' };
    const ogLocaleCurrent = `${currentLanguage}_${localeSuffixMap[currentLanguage]}`;
    const ogLocaleAlternate = `${oppositeLanguage}_${localeSuffixMap[oppositeLanguage]}`;

    const {
        url: siteHostname,
        header: {
            title: siteName,
            tagline: siteTagline,
            description: siteDescription,
            keywords: siteKeywords,
            developer: siteDeveloper,
            logo: siteLogo
        }
    } = strings;

    const pageTitles = Object.keys(strings.pages).reduce((reduced, page) => {
        const { title, tagline } = strings.pages[page];
        const current = { ...reduced };

        switch (page) {
            case 'main':
                current[page] = siteTagline;
                break;
            case 'error':
                current[page] = `${title} | ${tagline}`;
                break;
            default:
                current[page] = title;
                break;
        }

        return current;
    }, {});

    const pageTitle = Object.keys(pageTitles).includes(currentPage) ? pageTitles[currentPage] : pageTitles['error'];
    const shareTitle = [siteName, siteTagline].join(' • ');
    const defaultTitle = [siteName, pageTitle].join(' • ');
    const titleTemplate = `${siteName} • %s`;

    const metaTags = Object.entries({
        page: [
            { name: 'viewport', content: 'width=device-width, initial-scale=1.0, user-scalable=1' },
            { name: 'theme-color', content: '#111827' },
            { name: 'mobile-web-app-capable', content: 'yes' },
            { name: 'application-name', content: defaultTitle },
            { name: 'apple-mobile-web-app-title', content: defaultTitle },
            { name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords.join(', ') },
            ...['owner', 'author', 'designer'].map((item) => ({ name: item, content: siteDeveloper }))
        ],
        og: [
            { property: 'og:locale', content: ogLocaleCurrent },
            { property: 'og:locale:alternate', content: ogLocaleAlternate },
            { property: 'og:title', content: pageTitle },
            { property: 'og:description', content: siteDescription },
            { property: 'og:site_name', content: shareTitle },
            { property: 'og:type', content: 'website' },
            { property: 'og:image', content: `https://${siteHostname}/${siteLogo}` },
            { property: 'og:url', content: `https://${siteHostname}/` }
        ],
        twitter: [
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:site', content: '@m00nbyte' },
            { name: 'twitter:creator', content: '@m00nbyte' },
            { name: 'twitter:title', content: shareTitle },
            { name: 'twitter:description', content: siteDescription },
            { name: 'twitter:image', content: `https://${siteHostname}/${siteLogo}` }
        ],
        ms: [
            { name: 'msapplication-config', content: '/icons/ms/browserconfig.xml?v=1' },
            { name: 'msapplication-TileColor', content: '#111827' }
        ],
        other: [
            { name: 'audience', content: 'all' },
            { name: 'coverage', content: 'Worldwide' },
            { name: 'classification', content: 'Business' },
            { name: 'revisit-after', content: '7 days' }
        ]
    }).reduce((tags, current) => tags.concat(current[1]), []);

    const linkTags = Object.entries({
        page: isProduction
            ? [
                  { rel: 'manifest', href: `/${currentLanguage}.manifest.json` },
                  { type: 'text/plain', rel: 'author', href: '/humans.txt' }
              ]
            : [],
        canonical: [{ rel: 'canonical', href: `https://${siteHostname}/${currentLanguage}/${currentPage}` }],
        alternate: [...availableLanguages, 'x-default'].map((language) => ({
            rel: 'alternate',
            hrefLang: language,
            href: `https://${siteHostname}/${language === 'x-default' ? 'en' : language}/${currentPage}`
        })),
        preconnect: [
            siteHostname,
            'www.google-analytics.com',
            'www.googletagmanager.com',
            'maps.googleapis.com',
            'maps.gstatic.com',
            'api.iconify.design',
            'api.unisvg.com',
            'api.simplesvg.com'
            // 'img.shields.io'
        ].map((site) => ({
            rel: 'preconnect',
            href: `//${site}`
        })),
        favicons: Object.entries({
            page: { rel: 'shortcut icon', href: 'favicon.ico' },
            desktop: { rel: 'icon', sizes: ['16', '32'] },
            android: { rel: 'icon', sizes: ['36', '48', '72', '96', '144', '192', '512'] },
            ios: { rel: 'apple-touch-icon', sizes: ['120', '152', '167', '180', '1024'] },
            safari: { rel: 'mask-icon', href: 'safari-pinned-tab.svg', color: '#111827' },
            ms: { rel: 'msapplication-TileImage', sizes: ['70', '144', '150', '310'] }
        })
            .map((data) => {
                const { 0: dir, 1: list } = data;
                const { rel, sizes, href, color } = list;

                return dir === 'page'
                    ? {
                          rel,
                          href: `/${href}?v=1`
                      }
                    : dir === 'safari'
                    ? {
                          rel,
                          href: `/icons/apple/${href}?v=1`,
                          color: color
                      }
                    : sizes.map((size) => ({
                          rel,
                          type: 'image/png',
                          sizes: `${size}x${size}`,
                          href: `/icons/${dir}/icon_${size}x${size}.png?v=1`
                      }));
            })
            .reduce((data, list) => data.concat(list), [])
    }).reduce((tags, current) => tags.concat(current[1]), []);

    const scriptTags = Object.entries({
        ldJson: [
            {
                type: 'application/ld+json',
                innerHTML: JSON.stringify(ldJson, null, 2)
            }
        ]
    }).reduce((tags, current) => tags.concat(current[1]), []);

    return (
        <Helmet
            title={pageTitle}
            defaultTitle={defaultTitle}
            titleTemplate={titleTemplate}
            meta={metaTags}
            link={linkTags}
            script={scriptTags}
        />
    );
});

Header.propTypes = headerProps;

export default Header;
