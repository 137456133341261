// #region modules
import { Link } from 'react-scroll';
import shallow from 'zustand/shallow';
// #endregion
// #region functions
import cx from '@functions/classNames';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion
// #region types
import { menuButtonProps } from '@types/PropTypes';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    snapshot: state.snapshot,
    windowWidth: state.window.width,
    mobileBreakpoint: state.mobile.breakpoint,
    scrollDirection: state.scroll.direction
});

/**
 * navbar menu button
 * @param {object} item {title, element}
 * @returns {component} jsx component
 */
const MenuButton = ({ item: { title = 'Button', element = '#root' } }) => {
    const { snapshot, windowWidth, mobileBreakpoint, scrollDirection } = useStore(stateSelector, shallow);

    const buttonClass = [
        'ga-mb',
        'md:mx-4',
        'md:my-0',
        'text-black',
        'dark:text-white',
        'hover:text-gray-600',
        'dark:hover:text-gray-300',
        'tracking-wider',
        'lowercase',
        'font-lead',
        'cursor-pointer',
        windowWidth >= mobileBreakpoint &&
            [
                'custom-underline',
                scrollDirection === 'none' && 'underline-none',
                scrollDirection === 'down' && 'underline-down',
                scrollDirection === 'up' && 'underline-up'
            ].join(' ')
    ];

    return !snapshot ? (
        <Link
            href={`#${element}`}
            // title={title}
            to={element}
            spy={true}
            smooth={true}
            duration={800}
            offset={-34}
            ignoreCancelEvents={true}
            className={cx(buttonClass)}
            activeClass={cx('nav-active')}
        >
            {title}
        </Link>
    ) : (
        <a href={`#${element}`} title={title} className={cx(buttonClass)}>
            {title}
        </a>
    );
};

MenuButton.propTypes = menuButtonProps;

export default MenuButton;
