// #region modules
import { useEffect, useCallback, useRef } from 'react';
import shallow from 'zustand/shallow';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    scrollPosition: state.scroll.position,
    scrollDirection: state.scroll.direction,
    dispatch: state.dispatch
});

/**
 * get current scroll position and direction
 * @returns {object} {position, direction}
 */
const useScroll = () => {
    // add state
    const { scrollPosition, scrollDirection, dispatch } = useStore(stateSelector, shallow);

    // create a reference
    const resetDirection = useRef('');

    // handle event
    const handleUpdate = useCallback(
        (obj, log) => {
            // handle update
            dispatch({
                type: 'scroll',
                value: obj,
                log: `scroll/${log}`
            });
        },
        [dispatch]
    );

    // handle event
    const handleScroll = useCallback(
        (e) => {
            const scrollY = window.scrollY;

            clearTimeout(resetDirection.current);

            var h = document.documentElement,
                b = document.body,
                st = 'scrollTop',
                sh = 'scrollHeight';

            handleUpdate(
                {
                    position: window.pageYOffset,
                    percent: Math.round(((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100),
                    direction: (scrollPosition > scrollY && 'up') || (scrollPosition < scrollY && 'down')
                },
                'update'
            );

            resetDirection.current = setTimeout(() => handleUpdate({ direction: 'none' }, 'timeout'), 800);
        },
        [handleUpdate, scrollPosition]
    );

    // hook into window scroll event
    useEffect(() => {
        // setup listener
        window.addEventListener('scroll', handleScroll, { passive: true });

        // remove listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return { position: scrollPosition, direction: scrollDirection };
};

export default useScroll;
