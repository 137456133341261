// https://dev.to/gugaguichard/replace-clsx-classnames-or-classcat-with-your-own-little-helper-3bf

/**
 * join class names
 * @param {string|array} args class names
 * @returns {string} joined names
 */
const cx = (...args) =>
    args
        .flat()
        .filter((x) => x !== null && x !== undefined && typeof x !== 'boolean')
        .join(' ');

export default cx;
