// #region modules
import { HelmetProvider } from 'react-helmet-async';
// #endregion
// #region layout
import TagManager from '@layout/TagManager';
// #endregion
// #region types
import { providersProps } from '@types/PropTypes';
// #endregion

/**
 * wrap component with provider based on condition
 * @param {bool} condition wrap or not
 * @param {provider} wrapper provider (wrapper)
 * @param {component} children children component
 * @returns {provider} jsx component
 */
// const ConditionalProvider = ({ condition = false, wrapper = <></>, children = <></> }) => (condition ? wrapper(children) : children);

// ConditionalProvider.propTypes = {
//     condition: PropTypes.bool.isRequired,
//     wrapper: PropTypes.object.isRequired,
//     children: PropTypes.object.isRequired
// };

/**
 * chain providers together and wrap them around a child component
 * @param {component} children children component
 * @returns {provider} jsx component
 */
const AppProviders = ({ children = <></> }) => {
    return (
        <HelmetProvider>
            <TagManager>{children}</TagManager>
        </HelmetProvider>
    );
};

AppProviders.propTypes = providersProps;

export default AppProviders;
