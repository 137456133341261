const ads = {
    title: 'No Ads',
    lead: [
        "According to §107 TKG 2003, e-mail advertising is generally only permitted with the recipient's prior consent or within the framework of an existing business relationship.",
        'Therefore, please send us no direct advertising by phone or email!'
    ],
    ecg: {
        title: 'Electronic Robinson List - (ECG List)',
        text: 'The domain moonbyte.at is listed in the EKG list. This also includes all email addresses. By entering in the ECG list, it is again expressly pointed out that direct advertising is not allowed to be sent to that domain.',
        link: 'Learn more at: {wko}'
    }
};

export default ads;
