const consent = {
    en: {
        consent_modal: {
            title: '🍪 We use cookies!',
            description:
                'This website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
            primary_btn: {
                text: 'Accept all',
                role: 'accept_all' // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
                text: 'Reject all',
                role: 'accept_necessary' // 'settings' or 'accept_necessary'
            }
        },
        settings_modal: {
            title: '🍪 Cookie preferences',
            save_settings_btn: 'Save settings',
            accept_all_btn: 'Accept all',
            reject_all_btn: 'Reject all',
            close_btn_label: 'Close',
            cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Expiration' }, { col4: 'Description' }],
            blocks: [
                {
                    title: 'Cookie usage 📢',
                    description:
                        'This website uses cookies to ensure basic functionality and to improve your online experience. You can accept or reject statistical cookies at any time. For more details on cookies and other sensitive data, see the full privacy statement.'
                },
                {
                    title: 'Necessary cookies',
                    description:
                        'These cookies are essential for the proper functioning of the website. Without these cookies, the website would not function properly.',
                    toggle: {
                        value: 'necessary',
                        enabled: true,
                        readonly: true
                    }
                },
                {
                    title: 'Statistics cookies',
                    description:
                        'These cookies help to understand how visitors interact with the website by collecting and sending information anonymously.',
                    toggle: {
                        value: 'analytics'

                        // problem with localization library
                        // enabled: false,
                        // readonly: false
                    },
                    cookie_table: [
                        {
                            col1: '_ga',
                            col2: 'google.com',
                            col3: '1 year',
                            col4: 'Website visitors and tracking'
                        },
                        {
                            col1: '_gid',
                            col2: 'google.com',
                            col3: '1 day',
                            col4: 'Website visitors and tracking'
                        }
                    ]
                }
                // {
                //     title: 'Advertisement and Targeting cookies',
                //     description:
                //         'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                //     toggle: {
                //         value: 'targeting',
                //         enabled: false,
                //         readonly: false
                //     }
                // },
                // {
                //     title: 'More information',
                //     description: 'For any queries in relation to our policy on cookies and your choices, please contact us.'
                // }
            ]
        }
    },
    de: {
        consent_modal: {
            title: '🍪 Wir verwenden Cookies!',
            description:
                'Diese Webseite verwendet essentielle Cookies, um den ordnungsgemäßen Betrieb zu gewährleisten, und Tracking-Cookies, um zu verstehen, wie Sie damit interagieren. Letztere werden erst nach Zustimmung gesetzt. <button type="button" data-cc="c-settings" class="cc-link">Einstellungen verwalten</button>',
            primary_btn: {
                text: 'Alle akzeptieren',
                role: 'accept_all' // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
                text: 'Alles ablehnen',
                role: 'accept_necessary' // 'settings' or 'accept_necessary'
            }
        },
        settings_modal: {
            title: '🍪 Cookie Einstellungen ',
            save_settings_btn: 'Einstellungen speichern',
            accept_all_btn: 'Alle akzeptieren',
            reject_all_btn: 'Alles ablehnen',
            close_btn_label: 'Schließen',
            cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Ablauf' }, { col4: 'Beschreibung' }],
            blocks: [
                {
                    title: 'Cookie Nutzung 📢',
                    description:
                        'Diese Webseite verwendet Cookies, um die grundlegenden Funktionen sicherzustellen, und Ihr Online-Erlebnis zu verbessern. Sie können die Statistik Cookies jederzeit akzeptieren oder ablehnen. Weitere Einzelheiten zu Cookies und anderen sensiblen Daten finden Sie in der vollständigen Datenschutzerklärung.'
                },
                {
                    title: 'Erforderliche Cookies',
                    description:
                        'Diese Cookies sind für das reibungslose Funktionieren der Webseite unerlässlich. Ohne diese Cookies würde die Webseite nicht richtig funktionieren.',
                    toggle: {
                        value: 'necessary',
                        enabled: true,
                        readonly: true
                    }
                },
                {
                    title: 'Statistik Cookies',
                    description:
                        'Diese Cookies helfen zu verstehen, wie Besucher mit der Webseite interagieren, indem Informationen anonym gesammelt und gesendet werden.',
                    toggle: {
                        value: 'analytics'

                        // problem with localization library
                        // enabled: false,
                        // readonly: false
                    },
                    cookie_table: [
                        {
                            col1: '_ga',
                            col2: 'google.com',
                            col3: '2 Jahre',
                            col4: 'Eindeutige ID'
                        },
                        {
                            col1: '_gid',
                            col2: 'google.com',
                            col3: '1 Tag',
                            col4: 'Eindeutige ID'
                        }
                    ]
                }
                // {
                //     title: 'Werbe- und Targeting-Cookies',
                //     description:
                //         'Diese Cookies sammeln Informationen darüber, wie Sie die Website nutzen, welche Seiten Sie besucht und auf welche Links Sie geklickt haben. Alle Daten sind anonymisiert und können nicht dazu verwendet werden, Sie zu identifizieren',
                //     toggle: {
                //         value: 'targeting',
                //         enabled: false,
                //         readonly: false
                //     }
                // },
                // {
                //     title: 'Mehr Informationen',
                //     description:
                //         'Bei Fragen zu unserer Richtlinie zu Cookies und Ihren Auswahlmöglichkeiten wenden Sie sich bitte an uns.'
                // }
            ]
        }
    }
};

export default consent;
