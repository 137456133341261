// #region strings
import data from '@strings/static.strings';
// #endregion

const { url, themes, header, nav, footer } = data;

const global = {
    url,
    themes,
    header: {
        ...header,
        description: 'I am a trained web developer, network administrator and IT security manager from austria.',
        keywords: [
            'moonbyte',
            'm00nbyte',
            'developer',
            'programmer',
            'coder',
            'ecommerce',
            'e-commerce',
            'it consulting',
            'it security',
            'network security',
            'web security',
            'security',
            'html',
            'html5',
            'css',
            'css3',
            'javascript',
            'typescript',
            'nextjs',
            'tailwindcss',
            'mongodb',
            'redis'
        ]
    },
    nav: {
        menu: 'Menu',
        logo: nav.logo,
        home: nav.home,
        main: ['About', 'Services', 'Contact', 'Imprint'].map((item, index) => ({
            //'Portfolio',
            title: item,
            element: nav.main[index]
        })),
        btt: 'back to top' // back to top button text
    },
    language: {
        switch: 'Auf Deutsch wechseln'
    },
    footer: {
        links: ['Disclaimer', 'Privacy'].map((item, index) => ({
            title: item,
            page: footer.links[index]
        })),
        icons: footer.icons,
        tags: footer.tags,
        source: { title: 'This site is {link}', link: 'open sourced on Github' }
    },
    enableJS: {
        text: '{component} requires JavaScript.',
        button: 'Learn more'
    },
    consent: {
        manageCookies: 'Manage cookie settings',
        learnMore: 'Learn more...'
    }
};

export default global;
