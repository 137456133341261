// #region modules
import { memo } from 'react';
// #endregion
// #region functions
import cx from '@functions/classNames';
import ck from '@functions/customKey';
// #endregion
// #region types
import { screenSizeProps } from '@types/PropTypes';
// #endregion

/**
 * generate divs for each tailwind viewpoint
 * @returns {component} jsx component
 */
const Viewpoints = () => {
    // define viewpoints
    const viewpointList = ['default', 'sm', 'md', 'lg', 'xl', '2xl'];

    // render a div for each viewpoint
    const renderViewpoints = viewpointList.map((viewpoint) => (
        <div
            key={ck({ name: 'dev|screen|viewpoint', item: viewpoint })}
            className={cx([
                (viewpoint === 'default' && 'inline') || 'hidden',
                (viewpoint === 'sm' && 'sm:inline') || 'sm:hidden',
                (viewpoint === 'md' && 'md:inline') || 'md:hidden',
                (viewpoint === 'lg' && 'lg:inline') || 'lg:hidden',
                (viewpoint === 'xl' && 'xl:inline') || 'xl:hidden',
                (viewpoint === '2xl' && '2xl:inline') || '2xl:hidden'
            ])}
        >
            {viewpoint === 'default' ? '< 640px' : viewpoint}
        </div>
    ));

    return renderViewpoints;
};

/**
 * custom tailwind viewport infos:
 * current screen width & height
 * current tailwind breakpoint
 * @param {number} windowWidth current window width
 * @param {number} windowHeight current window height
 * @returns {component} jsx component
 */
const ScreenSize = memo(({ windowWidth = 0, windowHeight = 0 }) => {
    const textClass = ['bg-red-800', 'text-white', 'h-6', 'px-3', 'justify-center', 'items-center'];

    return (
        <div className={cx('flex', 'flex-row', 'fixed', 'bottom-0', 'left-0', 'z-50', 'space-x-2', 'cursor-default')}>
            <div className={cx(textClass)}>
                {windowWidth} x {windowHeight} px
            </div>
            <div className={cx(textClass)}>
                <Viewpoints />
            </div>
        </div>
    );
});

ScreenSize.propTypes = screenSizeProps;

export default ScreenSize;
