// #region modules
import { Cross as Hamburger } from 'hamburger-react';
import shallow from 'zustand/shallow';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion
// #region strings
import strings from '@strings/global.strings';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    mobileToggle: state.mobile.toggle,
    dispatch: state.dispatch
});

/**
 * navbar hamburger button
 * @returns {component} jsx component
 */
const MobileButton = () => {
    const { mobileToggle, dispatch } = useStore(stateSelector, shallow);

    return (
        <Hamburger
            size={20}
            distance="lg"
            direction="right"
            toggled={mobileToggle}
            toggle={(toggle) => dispatch({ type: 'mobile', value: { toggle }, log: 'hamburger/toggle' })}
            label={strings.nav.menu}
            ignoreCancelEvents={true}
        />
    );
};

export default MobileButton;
