// #region modules
import LazyLoad from 'react-lazyload';
// #endregion
// #region components
import NPMIcon from '@root/media/svg/npm';
// #endregion
// #region functions
import cx from '@functions/classNames';
// #endregion
// #region types
import { devIconProps } from '@types/PropTypes';
// #endregion

const api = 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons';

const iconList = [
    // languages
    { name: 'HTML5', dir: 'html5', file: 'html5-original' },
    { name: 'CSS3', dir: 'css3', file: 'css3-original' },
    { name: 'JavaScript', dir: 'javascript', file: 'javascript-original' },
    { name: 'TypeScript', dir: 'typescript', file: 'typescript-original' },

    // frameworks
    { name: 'NextJS', dir: 'nextjs', file: 'nextjs-original' },
    { name: 'Tailwind', dir: 'tailwindcss', file: 'tailwindcss-original' },
    { name: 'Rollup', dir: 'rollup', file: 'rollup-plain' },

    // database
    { name: 'MongoDB', dir: 'mongodb', file: 'mongodb-original' },
    { name: 'Redis', dir: 'redis', file: 'redis-original' },

    // social
    { name: 'Github', dir: 'github', file: 'github-original', invert: true },
    { name: 'NPM', dir: 'npm', file: 'npm-original-wordmark' },
    { name: 'Linkedin', dir: 'linkedin', file: 'linkedin-original' }
];

/**
 * dynamic icon component
 * @param {string} name name of the icon
 * @returns {component} jsx component
 */
const DevIcon = ({ name = 'Github' }) => {
    // temporary workaround for custom npm icon
    if (name === 'NPM') {
        return (
            <LazyLoad height={32} offset={200} once>
                <NPMIcon />
            </LazyLoad>
        );
    } else {
        const icon = iconList.find((icon) => icon.name === name);

        if (icon) {
            const { dir, file, invert } = icon;
            return (
                <LazyLoad height={32} offset={200} once>
                    <img src={`${api}/${dir}/${file}.svg`} alt={name} className={cx(invert && 'dark:invert')} />
                </LazyLoad>
            );
        } else {
            return <></>;
        }
    }
};

DevIcon.propTypes = devIconProps;

export default DevIcon;
