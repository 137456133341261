// #region modules
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
// #endregion
// #region components
import App from '@root/App';
// #endregion
// #region service worker
import * as serviceWorker from '@root/register-worker';
// #endregion
// #region web vitals
import reportWebVitals from '@root/reportWebVitals';
// #endregion

// display a console message
navigator.userAgent !== 'ReactSnap' &&
    console.log(
        [
            '\n',
            '👋 Hi there, fellow developer! Thanks for visiting.',
            '\n',
            "I'd love to hear what you think!",
            '\n\n',
            '— Sven',
            '\n\n'
        ].join('')
    );

// select an html element as root
const container = document.getElementById('root');

// create react root
const root = createRoot(container);

// render app component
root.render(
    <StrictMode>
        <App />
    </StrictMode>
);

/**
 * reload the page without waiting on new service worker installation
 * @param {function} serviceWorkerRegistration current registration
 */
const updateServiceWorker = (serviceWorkerRegistration) => {
    const registrationWaiting = serviceWorkerRegistration.waiting;

    if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        registrationWaiting.addEventListener('statechange', (e) => {
            if (e.target.state === 'activated') {
                window.location.reload();
            }
        });
    }
};

/**
 * initialize service worker
 * @param {object} config custom options
 */
serviceWorker.register({
    onUpdate: updateServiceWorker
});

/**
 * send core web vitals to ga4
 * @param {object} measurement CLS, FID, FCP, LCP, TTFB
 */
window.dataLayer = window.dataLayer || [];
reportWebVitals((measurement) => {
    window.dataLayer.push({
        event: 'web_vitals',
        measurement
    });
});
