// #region layout
import AppProviders from '@layout/Providers';
import GlobalHooks from '@layout/Hooks';
import CustomRoutes from '@layout/Routes';
// #endregion
// #region styles
import(/* webpackChunkName: "style.app" */ '@styles/app.css');
// #endregion

/**
 * wrap providers and hooks around routes
 * @returns {component} jsx component
 */
const App = () => {
    return (
        <AppProviders>
            <GlobalHooks>
                <CustomRoutes />
            </GlobalHooks>
        </AppProviders>
    );
};

export default App;
