// #region modules
import { Link } from 'react-scroll';
import shallow from 'zustand/shallow';
// #endregion
// #region components
import SlideButton from '@components/navbar/SlideButton';
// #endregion
// #region functions
import cx from '@functions/classNames';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion
// #region strings
import strings from '@strings/global.strings';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    snapshot: state.snapshot,
    scrollPosition: state.scroll.position,
    topElement: state.root
});

/**
 * navbar logo button
 * @returns {component} jsx component
 */
const LogoButton = () => {
    const { snapshot, scrollPosition, topElement } = useStore(stateSelector, shallow);

    const { logo, home, btt } = strings.nav;

    const buttonClass = [
        'ga-lb',
        'text-2xl',
        'lg:text-3xl',
        'ml-2',
        'lg:ml-0',
        'text-black',
        'dark:text-white',
        'font-bold',
        'hover:text-gray-600',
        'dark:hover:text-gray-300',
        'tracking-wider',
        'cursor-pointer',
        'font-display'
    ];

    return !snapshot ? (
        <Link
            href={`#${topElement}`}
            // title={home}
            to={topElement}
            spy={true}
            smooth={true}
            duration={800}
            offset={-68}
            ignoreCancelEvents={true}
            className={cx(buttonClass)}
            activeClass={cx('logo-active')}
        >
            <SlideButton title={logo} back={btt} slide={scrollPosition > 100} />
        </Link>
    ) : (
        <a href={`#${topElement}`} title={home} className={cx(buttonClass)}>
            {logo}
        </a>
    );
};

export default LogoButton;
