// #region modules
import LocalizedStrings from 'react-localization';
// #endregion
// #region strings
import en from '@strings/en/main.strings';
import de from '@strings/de/main.strings';
// #endregion

const strings = new LocalizedStrings({
    en: en,
    de: de
});

export default strings;
