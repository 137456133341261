// #region components
import DevIcon from '@components/global/DevIcon';
// #endregion
// #region functions
import cx from '@functions/classNames';
import ck from '@functions/customKey';
// #endregion
// #region strings
import strings from '@strings/global.strings';
// #endregion
// #region types
import { socialIconProps } from '@types/PropTypes';
// #endregion

/**
 * icon with hover effect
 * @param {object} item {icon, url}
 * @returns {component} jsx component
 */
const SocialIcon = ({ item: { icon = 'Github', url = '#' } }) => {
    return (
        <a
            href={url}
            title={icon}
            rel="noreferrer"
            target="_blank"
            className={cx('ga-si', 'hover:text-primary-gray-20', 'hover:scale-125', 'transform', 'duration-300')}
        >
            <div className={cx('w-8', 'h-8', 'pointer-events-none')}>
                <DevIcon name={icon} />
                <span className={cx('sr-only')}>{icon}</span>
            </div>
        </a>
    );
};

SocialIcon.propTypes = socialIconProps;

/**
 * social icons list
 * @returns {component} jsx component
 */
const SocialIcons = () => {
    const text = strings.footer;

    return (
        <div
            className={cx(
                'mt-4',
                'md:mt-0',
                'flex-1',
                'flex',
                'items-center',
                'justify-center',
                'space-x-4',
                'md:border-r',
                'border-gray-500',
                'dark:border-gray-100'
            )}
        >
            {text.icons.map((item) => (
                <SocialIcon key={ck({ name: 'footer|social|icon', item })} item={item} />
            ))}
        </div>
    );
};

export default SocialIcons;
