// #region hooks
import useConsent from '@hooks/useConsent';
import useFocus from '@hooks/useFocus';
import useSize from '@hooks/useSize';
import useScroll from '@hooks/useScroll';
import useTheme from '@hooks/useTheme';
// #endregion
// #region types
import { hooksProps } from '@types/PropTypes';
// #endregion

/**
 * setup global hooks
 * @param {component} children children component
 * @returns {component} jsx component
 */
const Hooks = ({ children = <></> }) => {
    useConsent();
    useFocus();
    useSize();
    useScroll();
    useTheme();

    return children;
};

Hooks.propTypes = hooksProps;

export default Hooks;
