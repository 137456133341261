const keyMap = new WeakMap();

let keyIndex = 0; // last index
let keySymbol = '🔑'; // key prefix

/**
 * generate an unique key for all the key props out there
 * @param {object} data content
 * @returns {string} unique key
 */
const ck = (data = { name: '', item: {} }) => {
    let key = keyMap.get(data);

    if (!key) {
        key = `${keySymbol} ${keyIndex++}`;
        keyMap.set(data, key);
    }

    return key;
};

export default ck;
