const disclaimer = {
    title: 'Disclaimer',
    paragraphs: [
        {
            title: 'EU dispute settlement',
            text: [
                "Consumers can submit complaints to the EU's online dispute resolution platform: {eu_odr}. You can also address any complaints to the email address given in the legal notice."
            ]
        },
        {
            title: 'Liability for the content of this website',
            text: [
                'We continuously develop the content of this website and strive to provide correct and current information. Unfortunately, we cannot accept any liability for the correctness of all content on this website, especially for those provided by third parties. If you notice problematic or illegal content, please contact us immediately.'
            ]
        },
        {
            title: 'Liability for links on this website',
            text: [
                'Our website contains links to other websites whosecontent we are not responsible for. Liability for linked websites does not exist for us according to §17 ECG, since we had no knowledge of illegal activities and we have not noticed any such illegal activities so far and we would remove links immediately if we became aware of illegal activities. If you notice any illegal links on our website, please contact us.'
            ]
        },
        {
            title: 'Embedded content from other websites',
            text: [
                'Posts on this website may contain embedded content (e.g. videos, pictures, posts etc.). Embedded content from other websites behaves exactly as if the visitor had visited the other website.',
                'These websites may collect data about you, use cookies, embed additional third-party tracking services, and record your interaction with this embedded content, including your interaction with the embedded content.'
            ]
        },
        {
            title: 'Notice within the meaning of the Equal Treatment Act',
            text: [
                'For reasons of easier readability, the gender-specific differentiation is not continuously taken into account on this website. Corresponding terms apply to all genders in the sense of equal treatment.'
            ]
        },
        {
            title: 'Copyright notice',
            text: [
                'All contents of this website (pictures, photos, texts, videos) are subject to copyright. If necessary, we will legally prosecute the unauthorized use of parts of the content on our site. If you find content on this website that violates copyright, please contact us.'
            ]
        },
        {
            title: 'Photo credits',
            text: [
                'The images, photos and graphics on this website are protected by copyright. The image rights belong to the following photographers/graphic designers: Sven Sommerbauer'
            ]
        }
    ]
};

export default disclaimer;
