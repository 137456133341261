// #region functions
import cx from '@functions/classNames';
// #endregion
// #region strings
import strings from '@strings/global.strings';
// #endregion
// #region types
import { manageConsentProps } from '@types/PropTypes';
// #endregion

/**
 * floating icon button
 * @param {string} currentTheme element id
 * @param {number} windowWidth current window width
 * @param {number} scrollPosition current scroll position
 * @param {number} mobileBreakpoint switch to mobile style
 * @returns {component} jsx component
 */
const ManageConsent = ({ currentTheme = '', windowWidth = 0, scrollPosition = 0, mobileBreakpoint = 0 }) => {
    const manageCookies = strings.consent.manageCookies;

    const buttonClass = [
        'ga-cb',
        'hover:w-auto',
        'fixed',
        'left-5',
        'bottom-5',
        'float-left',
        'flex',
        'items-center',
        'dark:text-white',
        'rounded-full',
        'transition-all',
        'cursor-pointer',
        'group',
        'z-10',
        scrollPosition < 100 && currentTheme === 'light' ? 'text-white' : 'text-black',
        ...((windowWidth <= mobileBreakpoint &&
            ((scrollPosition < 100 && ['hover:text-black', 'hover:bg-neutral-300', 'hover:dark:bg-neutral-700']) ||
                (scrollPosition > 100 && ['bg-neutral-300', 'dark:bg-neutral-700']))) ||
            [])
    ];

    return (
        <button
            className={cx(buttonClass)}
            onClick={(e) => {
                e.preventDefault();
                window.cc.showSettings();
            }}
            aria-hidden="false"
        >
            <div className={cx('flex', 'flex-row', 'items-center', 'pointer-events-none')}>
                <div className={cx('i-[la-cookie-bite]', 'm-2')} style={{ fontSize: '26px' }} />
                <div className={cx('hidden', 'hover:block', 'group-hover:flex')}>
                    <div
                        className={cx(
                            'dark:text-white',
                            'text-xs',
                            'rounded',
                            'py-1',
                            'right-0',
                            'bottom-full',
                            windowWidth <= mobileBreakpoint
                                ? ['pl-1', 'pr-3']
                                : [currentTheme === 'light' ? 'text-white' : 'text-black', 'bg-black', 'px-2']
                        )}
                    >
                        {manageCookies}
                    </div>
                </div>
                <span className={cx('sr-only')}>{manageCookies}</span>
            </div>
        </button>
    );
};

ManageConsent.propTypes = manageConsentProps;

export default ManageConsent;
