const privacy = {
    title: 'Privacy Policy',
    paragraphs: [
        {
            title: 'Declaration on the duty to provide information',
            text: [
                'The protection of your personal data is of particular concern to us. We therefore process your data exclusively on the basis of the legal provisions (GDPR, TKG 2003). In this data protection information we inform you about the most important aspects of data processing on our website.'
            ]
        },
        {
            title: 'Cookies',
            sub: [
                {
                    title: 'Use of cookies',
                    text: [
                        'This website uses various HTTP cookies to store personal data. Cookies are digital data packets that are used to exchange data between the web browser and server. This data is only used in combination with web applications such as plugins, analysis tools and online shops.',
                        'A distinction is made between first-party (direct) or third-party (indirect) cookies. Third-party cookies are, for example, Google Analytics or Facebook Pixel data.'
                    ]
                },
                {
                    title: 'Example of cookie data:',
                    text: [
                        'Cookies are then further divided into 3 subcategories, depending on their function.',
                        [
                            'strictly necessary cookies (for basic functions)',
                            'functional cookies (ensuring website performance)',
                            'targeted cookies (improve the user experience)'
                        ],
                        'By using cookies, your browser is immediately recognized on repeated visits. This offers you as a visitor a more user-friendly environment. As a result, web applications, images and information are loaded faster. Some cookies remain on your device until you delete them yourself in your browser settings.'
                    ]
                },
                {
                    title: 'View and delete cookie settings',
                    text: [
                        'You can quickly and easily find out which cookies are stored in your browser. You can also change or delete the settings of cookies. Find out more about your browser here: {cookiesandyou}',
                        'If you do not want cookies to be stored on your end device, you can configure your browser so that it informs you each time cookies are to be set and that they are only installed with permission. Cookies that are already on your device can be deleted, changed or deactivated at any time. You can find a lot of information on this topic if you google for example the terms "delete cookies".',
                        'If you decide to deactivate or delete all cookies, the smooth functioning of the website, pages, web shop and web applications can no longer be guaranteed.'
                    ]
                }
            ]
        },
        {
            title: 'Contact with us',
            text: [
                'If you contact us using the form on the website or by email, your data will be stored by us for the purpose of processing the request and in case of follow-up questions. This data will not be viewed by third parties, nor will we pass it on without your consent.'
            ]
        },
        {
            title: 'form.taxi',
            text: [
                'Our website uses form.taxi, a web service of the website {ft_link} (hereinafter "form.taxi"). In order to provide you with the functionality of the form, we send the data you provide to form.taxi. This data is processed and stored there and passed on to us by e-mail. In addition, form.taxi collects, among other things, further data such as your IP address, your browser type, the domain of the website, the date and time of access in order to provide the desired functionality of the form. The legal basis for the use of form.taxi is Art. 6 para. 1 p. 1 lit. f GDPR (legitimate interest). The data processing and storage takes place within the European Union. For more information, please refer to the privacy policy of form.taxi: {ft_privacy}.'
            ]
        },
        {
            title: 'Google Maps',
            text: [
                'This website uses the Google Maps service from the American company Google Inc., headquartered at 1600 Amphitheater Parkway Mountain View, CA 94043, USA.',
                'When using Google Maps, location data and data are forwarded to Google. You can see the data recorded by Google and how this data is used here: {gm_policy}'
            ]
        },
        {
            title: 'Google Analytics',
            text: [
                'This website uses the Google Analytics service from the American company Google Inc., headquartered at 1600 Amphitheater Parkway Mountain View, CA 94043, USA.',
                'Google Analytics uses targeted cookies.'
            ],
            sub: [
                {
                    title: 'Example of Google Analytics cookie data:',
                    text: [
                        [
                            ['Name', '_ga'],
                            ['Duration', '1 year'],
                            ['Area of application', 'Website visitors and tracking'],
                            ['Example value', 'GA1.2.32343453535.1234535880111']
                        ],
                        [
                            ['Name', '_gid'],
                            ['Duration', '1 year'],
                            ['Area of application', 'Website visitors and tracking'],
                            ['Example value', 'GA1.2.32343453535.1234535880111']
                        ],
                        [
                            ['Name', 'gat_gtag_UA'],
                            ['Duration', '3 minutes'],
                            ['Area of application', 'e.g. Integration of Google Analytics with Google Tag Manager'],
                            ['Example value', '3']
                        ],
                        'You can find more information about Google Analytics here:',
                        '{ga_marketing} oder {ga_support}'
                    ]
                },
                {
                    title: 'Pseudonymization of data',
                    text: [
                        'We are constantly working to improve our offer and presence within the framework of the GDPR.',
                        'The privacy of our customers and users is very important to us, which is why we pseudonymise and encrypt it. The processing of the data is based on the legal provisions of Section 96 Paragraph 3 TKG and Article 6 EU GDPR Paragraph 1 lit a (consent) and/or f (legitimate interest) of the GDPR.'
                    ]
                },
                {
                    title: 'Disable Google Analytics data query',
                    text: [
                        'In order to end the data query by Google Analytics, you must download and install the following browser add-on: {ga_optout}',
                        'Installing the plugin will not run Google Analytics JavaScript files (analytics.js, dc.js, ga,js). All websites and user-related data recorded by Google Analytics and their further processing can also be deactivated.'
                    ]
                },
                {
                    title: 'Addition to Google Analytics and data processing',
                    text: [
                        'We have a direct customer contract with Google to use Google Analytics. In connection with the concluded customer contract, we have also accepted the "Addition to data processing" for Google Analytics.',
                        'You can find more about the Google Analytics addendum on data processing here: {ga_addon}'
                    ]
                }
            ]
        },
        {
            title: 'Your rights',
            text: [
                'In principle, you have the right to information, correction, deletion, restriction, data portability, revocation and objection. If you believe that the processing of your data violates data protection law or your data protection claims have otherwise been violated, you can complain to the supervisory authority. In Austria this is the data protection authority.'
            ]
        }
    ]
};

export default privacy;
