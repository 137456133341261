// #region modules
import LazyLoad from 'react-lazyload';
// #endregion
// #region components
import Section from '@components/page/Section';
// #endregion
// #region functions
import cx from '@functions/classNames';
// #endregion
// #region strings
import strings from '@strings/global.strings';
// #endregion
// #region types
import { languageSwitchProps } from '@types/PropTypes';
// #endregion

/**
 * language switch page section
 * @param {string} page current page
 * @param {string} language current language
 * @returns {component} jsx component
 */
const LanguageSwitch = ({ page = '', language = '' }) => {
    const oppositeLanguage = language === 'en' ? 'de' : 'en';

    return (
        <Section id="language" classes={cx('w-full', 'text-black', 'dark:text-white', 'bg-dynamic')}>
            <h2>
                <a
                    href={`/${oppositeLanguage}/${page || 'main'}`}
                    title={strings.language.switch}
                    className={cx(
                        'ga-ls',
                        'w-full',
                        'h-full',
                        'py-20',
                        'flex',
                        'flex-row',
                        'justify-center',
                        'hover:bg-gray-200',
                        'dark:hover:bg-gray-800',
                        'text-3xl',
                        'font-lead',
                        'tracking-wider',
                        'lowercase',
                        'transition-all'
                    )}
                >
                    <LazyLoad height={30} offset={400} once>
                        <div className={cx('i-[cil-language]', 'mr-5', 'text-black', 'dark:text-white')} />
                    </LazyLoad>
                    {strings.language.switch}
                </a>
            </h2>
        </Section>
    );
};

LanguageSwitch.propTypes = languageSwitchProps;

export default LanguageSwitch;
