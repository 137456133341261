// #region modules
import { useEffect, useCallback } from 'react';
import { useLocation, Outlet, Navigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
// #endregion
// #region layout
import View from '@layout/View';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
import { getLanguage, setLanguage } from '@hooks/useLanguage';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    dispatch: state.dispatch
});

/**
 * • get url params [lang, page]
 * • set page language
 * • scroll to top on page load
 * • wrap outlet view
 * @returns {component} jsx component
 */
const Layout = () => {
    const { dispatch } = useStore(stateSelector, shallow);

    const { pathname } = useLocation();
    const [lang, page] = pathname.substring(1).split('/');

    const { availableLanguages, siteLanguage } = getLanguage(lang);
    const includedLanguage = availableLanguages.includes(lang);
    const currentLanguage = setLanguage(siteLanguage);

    const defaultPage = 'main';
    const defaultRoute = `/${currentLanguage}/${defaultPage}`;
    const currentPage = (includedLanguage && page) || 'error';
    const isMain = currentPage === defaultPage;

    const pageInfos = useCallback(() => {
        dispatch({
            type: 'page',
            value: {
                language: currentLanguage,
                current: currentPage,
                isMain
            },
            log: 'page/update'
        });
    }, [currentLanguage, currentPage, dispatch, isMain]);

    // scroll to top on page change
    useEffect(() => {
        window.scrollTo(0, 0);

        pageInfos();
    }, [pageInfos]);

    return !lang || !page ? (
        <Navigate to={defaultRoute} replace={true} />
    ) : (
        <View>
            <Outlet />
        </View>
    );
};

export default Layout;
