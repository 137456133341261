// #region strings
import exclude from '@strings/exclude.strings';
import mapStyles from '@strings/map.strings';
// #endregion

//todo: change arrays to named objects so that order does not matter?

const data = {
    url: 'moonbyte.at',
    themes: ['dark', 'light'],
    header: {
        title: 'moonbyte',
        tagline: 'beyond the limits',
        developer: 'm00nbyte',
        logo: 'img/logo_social.png'
    },
    nav: { logo: 'moonbyte', home: 'Home', main: ['about', 'services', 'contact', 'imprint'] }, //'portfolio',
    stack: ['HTML5', 'CSS3', 'JavaScript', 'TypeScript', 'NextJS', 'Tailwind', 'Rollup', 'MongoDB', 'Redis'],
    imprint: exclude.imprint,
    map: {
        styles: mapStyles
    },
    footer: {
        links: ['disclaimer', 'privacy'],
        icons: [
            {
                icon: 'Github',
                url: 'https://github.com/m00nbyte'
            },
            {
                icon: 'NPM',
                url: 'https://npmjs.com/~m00nbyte'
            },
            {
                icon: 'Linkedin',
                url: 'https://linkedin.com/in/m00nbyte'
            }
        ],
        tags: {
            made: 'made with {love} in austria',
            copy: '{icon} {copy} moonbyte.at'
        }
    }
};

export default data;
