// #region modules
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
// #endregion
// #region functions
import cx from '@functions/classNames';
import ck from '@functions/customKey';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion
// #region strings
import strings from '@strings/global.strings';
// #endregion
// #region types
import { internalLinkProps } from '@types/PropTypes';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    windowWidth: state.window.width,
    mobileBreakpoint: state.mobile.breakpoint,
    currentLanguage: state.page.language
});

/**
 * link button
 * @param {object} item {title, page}
 * @returns {component} jsx component
 */
const InternalLink = ({ item: { title = 'Link', page = '#' } }) => {
    const { windowWidth, mobileBreakpoint, currentLanguage } = useStore(stateSelector, shallow);
    const navigate = useNavigate();

    return (
        <button
            onClick={() => {
                navigate(`/${currentLanguage}/${page}`);
            }}
            className={cx(
                `ga-${page.substring(0, 2)}`,
                'h-10',
                'md:h-auto',
                'font-lead',
                'text-black',
                'dark:text-white',
                'hover:text-gray-600',
                'dark:hover:text-gray-300',
                'transition-all',
                'lowercase',
                windowWidth >= mobileBreakpoint && ['custom-underline', 'before:right-0']
            )}
        >
            {title}
        </button>
    );
};

InternalLink.propTypes = internalLinkProps;

/**
 * link button list
 * @returns {component} jsx component
 */
const InternalLinks = () => {
    const text = strings.footer;

    return (
        <div
            className={cx(
                'md:pr-5',
                'flex',
                'flex-1',
                'flex-col',
                'items-center',
                'md:items-end',
                'justify-center',
                'md:border-r',
                'border-gray-500',
                'dark:border-gray-100'
            )}
        >
            {text.links.map((item) => (
                <InternalLink key={ck({ name: 'footer|links|internal', item })} item={item} />
            ))}
        </div>
    );
};

export default InternalLinks;
