// #region modules
import { useEffect, useCallback } from 'react';
import shallow from 'zustand/shallow';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    windowWidth: state.window.width,
    windowHeight: state.window.height,
    dispatch: state.dispatch
});

/**
 * get current window width and height
 * @returns {object} {width, height}
 */
const useSize = () => {
    // add state
    const { windowWidth, windowHeight, dispatch } = useStore(stateSelector, shallow);

    // handle event
    const handleResize = useCallback(() => {
        // update state
        const { innerWidth: width, innerHeight: height } = window;
        dispatch({ type: 'window', value: { width, height }, log: 'size/update' });
    }, [dispatch]);

    // hook into window resize event
    useEffect(() => {
        // setup listener
        window.addEventListener('resize', handleResize);

        // remove listener
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    return { width: windowWidth, height: windowHeight };
};

export default useSize;
