const Icon = () => {
    /*
        // original
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
            <path fill="#cb3837" d="M2 38.5h124v43.71H64v7.29H36.44v-7.29H2zm6.89 36.43h13.78V53.07h6.89v21.86h6.89V45.79H8.89zm34.44-29.14v36.42h13.78v-7.28h13.78V45.79zm13.78 7.29H64v14.56h-6.89zm20.67-7.29v29.14h13.78V53.07h6.89v21.86h6.89V53.07h6.89v21.86h6.89V45.79z"></path>
        </svg>

    */
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
            <rect fill="#cc3534" x="4.83" y="4.83" width="118.35" height="118.35" rx="6.53" ry="6.53" />
            <path
                fill="#fff"
                d="M27.934 101.526h36.141l.046-54.119h18.024l-.046 54.165h18.07l.047-72.189-72.19-.092-.092 72.235z"
            />
        </svg>
    );
};

export default Icon;
