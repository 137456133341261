// #region modules
import { memo } from 'react';
// #endregion
// #region components
import Space from '@components/footer/Space';
import Links from '@components/footer/Links';
import Icons from '@components/footer/Icons';
import Tags from '@components/footer/Tags';
// import Source from '@components/footer/Source';
// #endregion
// #region functions
import cx from '@functions/classNames';
// #endregion

/**
 * footer section
 * @returns {component} jsx component
 */
const Footer = memo(() => {
    return (
        <>
            <footer
                className={cx(
                    'bg-dynamic',
                    'px-3',
                    'py-8',
                    'text-2',
                    'text-gray-500',
                    'dark:text-gray-200',
                    'transition-colors',
                    'duration-200'
                )}
            >
                <div className={cx('mt-4', 'md:mt-0', 'flex', 'flex-col', 'md:flex-row')}>
                    <Links />
                    <Space />
                    <Icons />
                    <Space />
                    <Tags />
                </div>
            </footer>
            {/* <Source /> */}
        </>
    );
});

export default Footer;
