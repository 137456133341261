const exclude = {
    imprint: {
        title: 'moonbyte.at',
        name: 'Sven Sommerbauer',
        street: 'Liechtensteinstraße 16/3',
        zip: '2344',
        city: 'Maria Enzersdorf',
        countryTag: 'AT',
        email: 'office@moonbyte.at',
        phone: '+43 (0) 676 / 412 32 76',
        vatValue: '16 330/6863',
        foundingDate: '2020',
        sameAs: [
            'https://keybase.io/m00nbyte',
            'https://github.com/m00nbyte/',
            'https://npmjs.com/~m00nbyte',
            'https://facebook.com/m00nbyte/',
            'https://twitter.com/m00nbyte/',
            'https://instagram.com/m00nbyte/',
            'https://g.page/r/Ca52MtkaYtbyEBA',
            'https://linkedin.com/in/sven-sommerbauer-655b971b1/'
        ]
    }
};

export default exclude;
