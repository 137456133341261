// import modules
import shallow from 'zustand/shallow';
// #endregion
// #region components
import MobileButton from '@components/navbar/MobileButton';
import BackButton from '@components/navbar/BackButton';
import ThemeToggle from '@components/navbar/ThemeToggle';
// #endregion
// #region functions
import cx from '@functions/classNames';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    snapshot: state.snapshot,
    isMain: state.page.isMain
});

/**
 *
 * navbar helpers
 * @returns {component} jsx component
 */
const HelpersBar = () => {
    const { snapshot, isMain } = useStore(stateSelector, shallow);

    return (
        <div className={cx('flex', 'md:hidden', 'items-center')}>
            {!snapshot && <ThemeToggle />}

            <span className={cx('ml-3', 'text-black', 'dark:text-white', 'z-10')}>
                {isMain ? !snapshot && <MobileButton /> : <BackButton />}
            </span>
        </div>
    );
};

export default HelpersBar;
