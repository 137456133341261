// #region functions
import cx from '@functions/classNames';
// #endregion
// #region types
import { slideButtonProps } from '@types/PropTypes';
// #endregion

/**
 * slide text button
 * @param {string} title logo text
 * @param {string} back back to top text
 * @param {bool} slide change text
 * @returns {component} jsx component
 */
const SlideButton = ({ title, back, slide }) => {
    const slideClass = ['transition-all', 'duration-300', 'ease-out', 'group'];
    const backClass = ['absolute', '-ml-3', 'flex', 'items-center', 'text-lg'];
    const iconClass = ['i-[gg-border-top]', 'm-2', 'transform', 'transition-all', 'pointer-events-none'];

    return (
        <div className={cx('flex', 'items-center')}>
            <div
                className={cx(slideClass, (!slide ? ['opacity-100'] : ['opacity-0', 'z-negative', '-translate-x-3']).join(' '))}
            >
                {title}
            </div>
            <div
                className={cx(
                    slideClass,
                    backClass,
                    (slide ? ['opacity-100'] : ['opacity-0', 'z-negative', '-translate-x-3']).join(' ')
                )}
            >
                <div className={cx(iconClass)} style={{ fontSize: '28px' }} />
                <span className={cx('font-lead', 'lowercase', 'font-normal')}>{back}</span>
            </div>
        </div>
    );
};

SlideButton.propTypes = slideButtonProps;

export default SlideButton;
