// #region modules
import { Events } from 'react-scroll';
import shallow from 'zustand/shallow';
// #endregion
// #region components
import Header from '@components/page/Header';
import Navbar from '@components/page/Navbar';
import Footer from '@components/page/Footer';
import ManageConsent from '@components/global/ManageConsent';
import LanguageSwitch from '@components/global/LanguageSwitch';
import ScreenSize from '@components/global/ScreenSize';
// #endregion
// #region functions
import cx from '@functions/classNames';
// #endregion
// #region hooks
import useStore from '@hooks/useStore';
import usePageview from '@hooks/usePageview';
// #endregion
// #region types
import { viewProps } from '@types/PropTypes';
// #endregion

/**
 * required state
 * @returns {object} state
 */
const stateSelector = (state) => ({
    snapshot: state.snapshot,
    production: state.production,
    page: state.page.current,
    language: state.page.language,
    isMain: state.page.isMain,
    currentTheme: state.theme,
    windowWidth: state.window.width,
    windowHeight: state.window.height,
    scrollPosition: state.scroll.position,
    mobileBreakpoint: state.mobile.breakpoint,
    mobileToggle: state.mobile.toggle,
    dispatch: state.dispatch
});

/**
 * setup current view
 * @param {component} children children component
 * @returns {component} jsx component
 */
const View = ({ children = <></> }) => {
    const {
        snapshot,
        production,
        page,
        language,
        isMain,
        currentTheme,
        windowWidth,
        windowHeight,
        scrollPosition,
        mobileBreakpoint,
        mobileToggle,
        dispatch
    } = useStore(stateSelector, shallow);

    Events.scrollEvent.register(
        'begin',
        (to, element) => mobileToggle && dispatch({ type: 'mobile', value: { toggle: false }, log: 'scroll/event' })
    );

    // gtm pageview event
    usePageview(page);

    return (
        <>
            <Header isProduction={production} currentPage={page} currentLanguage={language} />

            <main className={cx('w-full', 'h-full')}>
                <Navbar snapshot={snapshot} isMain={isMain} scrollPosition={scrollPosition} />
                {children}
                <LanguageSwitch page={page} language={language} />
                <Footer />
            </main>

            {!snapshot && (
                <>
                    <ManageConsent
                        currentTheme={currentTheme}
                        windowWidth={windowWidth}
                        scrollPosition={scrollPosition}
                        mobileBreakpoint={mobileBreakpoint}
                    />

                    {!production && <ScreenSize windowWidth={windowWidth} windowHeight={windowHeight} />}
                </>
            )}
        </>
    );
};

View.propTypes = viewProps;

export default View;
